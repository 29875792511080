import {
  Button,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField
} from "@mui/material"
import React, { useState } from "react"
import trashIcon from "../../assets/svg/trash.svg"

function AllFeedbacks({
  handleChange,
  addnew,
  titleHotel,
  _addHotel,
  feedbacks,
  _deleteHotel,
  openUpdate,
  offsetParent,
  count,
  clickNext,
  rowsPage,
  loading
}) {
  const [page, setPage] = React.useState(0)
  const [state, setState] = useState({
    visible: false,
    text: "",
    loadingSend: false
  })
  const {
    visible,
    text,
    loadingSend
  } = state

  React.useEffect(() => {
    if (count === 0) {
      setPage(0)
    }
  }, [count])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    if (clickNext) {
      const offset =
        newPage === page + 1 ? offsetParent + 20 : offsetParent - 20
      clickNext && clickNext(offset)
    }
  }

  const handleChangeLocal = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  const onClose = () => {
    handleChangeLocal('visible', false)
  }

  const onPress = () => {
    try {
      handleChangeLocal('loadingSend', false)
      handleChangeLocal('visible', false)
    } catch (error) {
      alert(error?.message)
    }
  }

  return (
    <Paper>
      <Grid container justifyContent={"space-between"}>
        <Grid item md={openUpdate ? 4 : addnew ? 9 : 12} xs={12} sx={{ p: 1 }}>
          <TableContainer sx={{ padding: 1, height: "auto" }}>
            <Table
              sx={{ height: "auto" }}
              aria-labelledby="tableTitle"
              size={"medium"}
            >
              {loading && (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    alignItems: "center",
                    marginTop: 10,
                    display: "flex",
                    justifyContent: "center"
                  }}
                >
                  <CircularProgress style={{ width: 20, height: 20 }} />
                </div>
              )}
              <TableHead sx={{ backgroundColor: "#F8F8FA" }}>
                <TableRow>
                  <TableCell padding="checkbox"></TableCell>
                  <TableCell
                    align={"left"}
                    padding={"normal"}
                    sortDirection={false}
                    sx={{ color: "rgba(8, 68, 107, 1)" }}
                  >
                    {"User"}
                  </TableCell>
                  <TableCell
                    align={"left"}
                    padding={"normal"}
                    sortDirection={false}
                    sx={{ color: "rgba(8, 68, 107, 1)" }}
                  >
                    {"Title"}
                  </TableCell>
                  <TableCell
                    align={"left"}
                    padding={"normal"}
                    sortDirection={false}
                    sx={{ color: "rgba(8, 68, 107, 1)" }}
                  >
                    {"Message"}
                  </TableCell>
                  <TableCell
                    align={"left"}
                    padding={"normal"}
                    sortDirection={false}
                    sx={{ color: "rgba(8, 68, 107, 1)" }}
                  >
                    {"Action"}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {feedbacks.map((item, index) => {
                  return (
                    <TableRow hover key={index}>
                      <TableCell padding="checkbox"></TableCell>
                      <TableCell
                        align="left"
                      >
                        <div
                          style={{ cursor: "pointer" }}
                        >
                          <img src={item?.user?.profile?.profile_photo} className="userDP" />
                          {item?.user?.profile?.name}
                        </div>
                      </TableCell>
                      <TableCell
                        align="left"
                      >
                        <div
                          style={{ cursor: "pointer" }}
                        >
                          {item?.title}
                        </div>
                      </TableCell>
                      <TableCell
                        align="left"
                      >
                        <div
                          style={{ cursor: "pointer" }}
                        >
                          {item?.message}
                        </div>
                      </TableCell>
                      <TableCell
                        align="left"
                      >
                        <Button variant="contained" onClick={() => {
                          handleChangeLocal("visible", true)
                        }}>
                          Reply
                        </Button>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Dialog onClose={onClose} open={visible}>
          <div className={"zipModal"}>
            <Grid className={"mt-3 mb-3"} spacing={2}>
              <h5>Response Feedback</h5>
            </Grid>
            <TextField
              variant="outlined"
              className={""}
              sx={{width:'95%'}}
              value={text}
              label="Write a message"
              placeholder="Write a message..."
              onChange={value =>
                handleChangeLocal("text", value.target.value)
              }
            />
            <div className="d-flex justify-content-center mt-4">
              <Button className=" mr-3 width150" onClick={onClose}>
                Cancel
              </Button>
              <Button className="primaryButton mr-3 width150" onClick={onPress}>
                {loadingSend ? (
                  <CircularProgress
                    style={{ color: "#fff", width: 20, height: 20 }}
                  />
                ) : (
                  "Send"
                )}
              </Button>
            </div>
          </div>
        </Dialog>
      </Grid>
    </Paper>
  )
}

export default AllFeedbacks
