import * as React from "react"
import {
  Grid,
  Container,
  Box,
  Tabs,
  Tab,
  Button,
  Dialog,
  CircularProgress
} from "@mui/material"
import { AllFeedbacks, DeleteModal, Layout } from "../../components"
import AppTable from "../../components/AppTable"
import AppContext from "../../Context"
import { useContext } from "react"
import { useNavigate } from "react-router-dom"
import {
  addHotel,
  deleteHotel,
  getAllFeedbacks,
  updateHotel
} from "../../api/admin"
import { useEffect } from "react"
import { getError } from "../../constants/error"

function FeedbacksContent() {
  const { locations, tools } = useContext(AppContext)
  const navigate = useNavigate()
  const [state, setState] = React.useState({
    value: "1",
    categoryTitle: "",
    selectedTitle: "",
    titleLocation: "",
    accessibility: "",
    accessibility1: "",
    titleTool: "",
    titleChecklist: "",
    toolChecklist: "",
    locationChecklist: "",
    hours: 0,
    minutes: 0,
    addnew: false,
    addnewTool: false,
    openCat: false,
    loadingQR: false,
    toolQR: "",
    hotel: "",
    locationQR: "",
    openQRModal: false,
    openHot: false,
    hotel: "",
    titleHotel: "",
    description: "",
    selectedLocation: "",
    openUpdate: false,
    addnewHotel: false,
    loading: false,
    filteredList: [],
    count: 0,
    limit: 20,
    page: 0,
    feedbacks: [],
    visible: false,
    name: "",
    id: "",
    loadingDelete: false
  })

  const {
    value,
    titleHotel,
    description,
    selectedLocation,
    openUpdate,
    openHot,
    addnewHotel,
    filteredList,
    count,
    limit,
    feedbacks,
    loading,
    page,
    visible,
    name,
    id,
    loadingDelete
  } = state

  const handleChange = (name, value) => {
    setState(pre => ({ ...pre, [name]: value }))
  }

  // useEffect(() => {
  //   if (hotels) {
  //     handleChange("filteredList", hotels)
  //   }
  // }, [hotels])

  useEffect(() => {
    getData(true)
  }, [])

  const getData = (resetPage, paged) => {
    const payload = `?limit=${limit}&offset=${resetPage ? 0 : paged}`
    _getAllFeedbacks(payload, resetPage)
  }

  const _getAllFeedbacks = async (payload, resetPage) => {
    try {
      handleChange("feedbacks", [])
      handleChange("loading", true)
      const queryParams = payload ? payload : ""
      const token = localStorage.getItem("token")
      const res = await getAllFeedbacks(token)
      handleChange("feedbacks", res?.data)
      handleChange("filteredList", res?.data)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      getError(error, navigate)
    }
  }

  const clickNext = newPage => {
    getData(false, newPage)
    handleChange("page", newPage)
  }

  const filtered = value => {
    const payload = `?limit=${limit}&offset=${0}&search=${value}`
    _getAllFeedbacks(payload, true)
    handleChange("count", 0)
    handleChange("page", 0)
    // if (value) {
    //   const re = new RegExp(value, "i")
    //   var filtered = hotels?.filter(entry =>
    //     Object.values(entry).some(
    //       val => typeof val === "string" && val.match(re)
    //     )
    //   )
    //   handleChange("filteredList", filtered)
    // } else {
    //   handleChange("filteredList", hotels)
    // }
  }

  const _addHotel = async () => {
    try {
      const token = localStorage.getItem("token")
      var formdata = new FormData()
      formdata.append("name", titleHotel)
      formdata.append("description", description)
      // formdata.append('locations', selectedLocationID)
      console.log("payload", formdata)
      await addHotel(formdata, token)
      handleChange("addnewHotel", false)
      handleChange("titleHotel", "")
      handleChange("description", "")
      handleChange("selectedLocation", "")
      handleChange("selectedLocationID", "")
      _getAllFeedbacks()
    } catch (error) {
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const _deleteHotel = async () => {
    try {
      handleChange("loadingDelete", true)
      const token = localStorage.getItem("token")
      await deleteHotel(id, token)
      _getAllFeedbacks()
      onClose()
      handleChange("loadingDelete", false)
      alert("Hotel has been deleted")
    } catch (error) {
      handleChange("loadingDelete", false)
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const _updateHotel = async (id, payload) => {
    try {
      const token = localStorage.getItem("token")
      await updateHotel(id, payload, token)
      _getAllFeedbacks()
      alert("Hotel Updated!")
    } catch (error) {
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const onDeleteModal = (id, name) => {
    handleChange("visible", true)
    handleChange("id", id)
    handleChange("name", name)
  }

  const onClose = () => {
    handleChange("visible", false)
    handleChange("id", "")
    handleChange("name", "")
  }

  console.log("feedbacks", feedbacks)
  return (
    <Layout>
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        {/* <Grid container>
          <div class="search">
            <span class="form-element">
              <span class="fa fa-search"></span>
              <input
                placeholder="SEARCH FEEDBACK"
                onChange={value => filtered(value.target.value)}
              />
            </span>
          </div>
        </Grid> */}
        {/* <Grid container justifyContent={"flex-end"} className="mt-3">
          <Button
            onClick={() => {
              handleChange("addnewHotel", true)
              handleChange("openUpdate", false)
            }}
            className="primaryButton"
          >
            Add New Hotel
          </Button>
        </Grid> */}
        <Box sx={{ width: "100%", mt: 2 }}>
          <Tabs
            value={value}
            onChange={(e, value) => handleChange("value", value)}
            classes={{ indicator: "indicator", root: "tabs" }}
            aria-label="secondary tabs example"
          >
            <Tab
              value="1"
              classes={{ selected: "activeTab" }}
              className="tabs"
              label="Feedback"
            />
          </Tabs>
        </Box>
        <AllFeedbacks
          addnew={addnewHotel}
          feedbacks={filteredList}
          loading={loading}
          _deleteHotel={onDeleteModal}
          _addHotel={_addHotel}
          handleChange={handleChange}
        />
        <DeleteModal
          visible={visible}
          onPress={_deleteHotel}
          onClose={onClose}
          loading={loadingDelete}
          description={
            "Are you sure you want to delete this hotel " + `"` + name + `"`
          }
        />
      </Container>
    </Layout>
  )
}

export default function Feedbacks() {
  return <FeedbacksContent />
}
