import * as React from "react"
import {
  Grid,
  Container,
  Button,
  Dialog,
  Typography,
  CircularProgress
} from "@mui/material"
import { DeleteModal, Layout } from "../../components"
import AppTable from "../../components/AppTable"
import { useContext } from "react"
import AppContext from "../../Context"
import { useState } from "react"
import { addUser, deleteUser, getAllUsers, updateUser } from "../../api/admin"
import { useEffect } from "react"
import { getError } from "../../constants/error"
import { useNavigate } from "react-router-dom"

const headCells1 = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name"
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email"
  },
  {
    id: "bio",
    numeric: false,
    disablePadding: false,
    label: "Bio"
  },
  {
    label: "Ad Image Photo",
    numeric: false,
    disablePadding: false,
    id: "ad_image_photo"
  },
  {
    label: "Favorite Food Photo",
    numeric: false,
    disablePadding: false,
    id: "favorite_food_photo"
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
    buttonText: "Delete",
    flagUser: true
  }
]
function UsersContent() {
  const { hotelsDropdown } = useContext(AppContext)
  const navigate = useNavigate()
  const [state, setState] = useState({
    filteredList: [],
    allUsers: [],
    open: "",
    name: "",
    role: "",
    hotel: "",
    email: "",
    password: "",
    loading: false,
    limit: 20,
    page: 0,
    count: 0,
    visible: false,
    username: "",
    id: "",
    loadingDelete: false,
    searchText: ""
  })
  const {
    filteredList,
    allUsers,
    open,
    name,
    role,
    email,
    hotel,
    password,
    loading,
    page,
    limit,
    count,
    visible,
    username,
    id,
    loadingDelete,
    searchText
  } = state
  const handleChange = (name, value) => {
    setState(pre => ({ ...pre, [name]: value }))
  }

  useEffect(() => {
    getData(0)
  }, [])

  const getData = paged => {
    const payload = `?limit=${limit}&offset=${paged}&search=${searchText}`
    _getAllUsers(payload)
  }

  const _getAllUsers = async payload => {
    try {
      handleChange("allUsers", [])
      handleChange("loading", true)
      const queryParams = payload ? payload : ""
      const token = localStorage.getItem("token")
      const res = await getAllUsers(queryParams, token)
      handleChange("allUsers", res?.data)
      handleChange("filteredList", res?.data)
      handleChange("count", res?.data?.count)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      getError(error, navigate)
    }
  }

  const filtered = value => {
    handleChange("searchText", value)
    if (value) {
      const re = new RegExp(value, "i")
      var filtered = allUsers?.filter(entry =>
        entry?.profile?.name?.toLowerCase()?.includes(value?.toLowerCase())
      )
      handleChange("filteredList", filtered)
    } else {
      handleChange("filteredList", allUsers)
    }
    // const payload = `?limit=${limit}&offset=${0}&search=${value}`
    // _getAllUsers(payload)
    // handleChange("count", 0)
    // handleChange("page", 0)
  }

  const _updateUser = async (id, flag) => {
    try {
      const token = localStorage.getItem("token")
      const payload = {
        flag
      }
      await updateUser(id, payload, token)
      getData(0)
    } catch (error) {
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const _deleteUser = async () => {
    try {
      handleChange("loadingDelete", true)
      const token = localStorage.getItem("token")
      await deleteUser(id, token)
      getData(0)
      onClose()
      handleChange("loadingDelete", false)
    } catch (error) {
      handleChange("loadingDelete", false)
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${error?.response?.data}`)
    }
  }

  const handleClose = value => {
    handleChange("open", value)
  }

  const _addUser = async () => {
    try {
      handleChange("loading", true)
      const token = localStorage.getItem("token")
      const payload = {
        name,
        role,
        email,
        password,
        hotel
      }
      await addUser(payload, token)
      getData(0)
      handleClose(false)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const clickNext = newPage => {
    getData(newPage)
    handleChange("page", newPage)
  }

  const onClose = () => {
    handleChange("visible", false)
    handleChange("id", "")
    handleChange("username", "")
  }

  const onDeleteModal = (id, name) => {
    handleChange("visible", true)
    handleChange("id", id)
    handleChange("username", name)
  }

  console.log("allUsers", allUsers)
  return (
    <Layout>
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container>
          <div class="search">
            <span class="form-element">
              <span class="fa fa-search"></span>
              <input
                placeholder="Search users"
                onChange={value => filtered(value.target.value)}
              />
            </span>
          </div>
        </Grid>
        <Grid
          container
          justifyContent={"space-between"}
          className="mt-3"
          alignItems={"center"}
        >
          <Typography className="text_primary font-weight-bold">
            List Of All The Users
          </Typography>
          {/* <Button onClick={() => handleClose(true)} className="primaryButton">
            Add New User
          </Button> */}
        </Grid>
        {allUsers && (
          <AppTable
            rows={filteredList}
            noPagination
            isUser
            count={count}
            offsetParent={page}
            loading={loading}
            clickNext={clickNext}
            flagAction={_updateUser}
            onClickButton={onDeleteModal}
            // rowsPage={5}
            headingLeft={"User activity"}
            headingRight={""}
            headCells={headCells1}
          />
        )}
      </Container>
      <Dialog onClose={() => handleClose(false)} open={open}>
        <div className={"zipModal"}>
          <Grid container justifyContent={"space-between"} spacing={2}>
            <Grid item xs={12} md={6}>
              <input
                className={"zipcode"}
                placeholder="Name"
                onChange={value => handleChange("name", value.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <input
                className={"zipcode"}
                placeholder="email"
                onChange={value => handleChange("email", value.target.value)}
              />
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent={"space-between"}
            className={"mt-3"}
            spacing={2}
          >
            <Grid item xs={12} md={6}>
              <input
                className={"zipcode"}
                placeholder="Password"
                onChange={value => handleChange("password", value.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <select
                className="zipcode"
                onClick={value => handleChange("role", value.target.value)}
              >
                <option value={""}>Select Role</option>
                <option value={"Area Manager"}>Area Manager</option>
                <option value={"General Manager"}>General Manager</option>
                <option value={"Staff"}>Staff</option>
                <option value={"Contract Maintenance"}>
                  Contract Maintenance
                </option>
                <option value={"House Keeping"}>House Keeping</option>
                <option value={"Admin"}>Admin</option>
              </select>
            </Grid>
            {/* <Grid item xs={12} md={6}>
              <select
                className="zipcode"
                onClick={value => handleChange("hotel", value.target.value)}
              >
                <option value={""}>Select Hotel</option>
                {hotelsDropdown?.length > 0 &&
                  hotelsDropdown?.map((hotel, index) => (
                    <option key={index} value={hotel?.id}>
                      {hotel?.name}
                    </option>
                  ))}
              </select>
            </Grid> */}
          </Grid>
          <div className="d-flex justify-content-center mt-4">
            <Button
              disabled={!name || !email || !role || !password}
              className="primaryButton mr-3 width150"
              onClick={_addUser}
            >
              {loading ? (
                <CircularProgress
                  style={{ color: "#fff", width: 20, height: 20 }}
                />
              ) : (
                "Add"
              )}
            </Button>
            <Button
              disabled={!name || !email || !role || !password}
              className="primaryButton"
            >
              Share Access
            </Button>
          </div>
        </div>
      </Dialog>
      <DeleteModal
        visible={visible}
        onPress={_deleteUser}
        onClose={onClose}
        loading={loadingDelete}
        description={
          "Are you sure you want to delete this user " + `"` + username + `"`
        }
      />
    </Layout>
  )
}

export default function Users() {
  return <UsersContent />
}
