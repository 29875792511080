import { Button, Grid, Paper, TablePagination } from "@mui/material"
import React, { Component } from "react"
import trashIcon from "../../assets/svg/trash.svg"
import DownIcon from "../../assets/svg/down.svg"
import Box from "@mui/material/Box"
import OutlinedInput from "@mui/material/OutlinedInput"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import Chip from "@mui/material/Chip"
import { useTheme } from "@mui/material/styles"
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium
  }
}

function AllLocation({
  handleChange,
  addnew,
  categories,
  openCat,
  _addCategory,
  categoryTitle,
  selectedTitle,
  titleLocation,
  accessibility,
  _addLocation,
  locations,
  _deleteLocation,
  checklists,
  _deleteChecklist,
  tools,
  _addChecklist,
  locationChecklist,
  toolChecklist,
  titleChecklist,
  rowsPage,
  clickNext,
  count,
  offsetParent,
  accessTypes
}) {
  const theme = useTheme()
  const [page, setPage] = React.useState(offsetParent || 0)
  const [rowsPerPage, setRowsPerPage] = React.useState(rowsPage || 20)

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleChangeDropdown = event => {
    const {
      target: { value }
    } = event
    handleChange(
      "accessibility",
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    )
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    if (clickNext) {
      const offset =
        newPage === page + 1 ? offsetParent + 20 : offsetParent - 20
      clickNext && clickNext(offset)
    }
  }

  return (
    <Paper>
      <Grid container justifyContent={"space-between"}>
        <Grid item xs={12} sx={{ p: 1 }}>
          <div className="head">Hot keywords panels</div>
          {locations?.map((item, index) => (
            <Grid
              key={index}
              container
              justifyContent={"space-between"}
              className={"listItem"}
            >
              <div style={{ maxWidth: "90%" }}>{item?.title}</div>
              <div
                className="c-pointer"
                onClick={() =>
                  _deleteLocation(item?.id, item?.title, "location")
                }
              >
                <img src={trashIcon} />
              </div>
            </Grid>
          ))}
          <TablePagination
            rowsPerPageOptions={[20]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default AllLocation
