import * as React from "react"
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Typography,
  IconButton,
  Switch,
  CircularProgress
} from "@mui/material"
import { alpha } from "@mui/material/styles"
import moment from "moment"
import WarningIcon from "../../assets/svg/warninggrey.svg"
import WarningRedIcon from "../../assets/svg/warningRed.svg"

function EnhancedTableHead(props) {
  const { headCells, feedback } = props

  return (
    <TableHead sx={{ backgroundColor: "#F8F8FA" }}>
      <TableRow>
        {!feedback && <TableCell padding="checkbox"></TableCell>}
        {headCells.map(headCell => {
          if (!headCell?.noAction) {

            return (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
                sortDirection={false}
                sx={{ color: "rgba(8, 68, 107, 1)" }}
              >
                {headCell.label}
              </TableCell>

            )
          }
        })}
      </TableRow>
    </TableHead>
  )
}

const EnhancedTableToolbar = props => {
  const { numSelected, headingRight, headingLeft, goto } = props

  return (
    <Toolbar
      className="header"
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: theme =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            )
        })
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%", color: "rgba(8, 68, 107, 1)", fontSize: 18 }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        {headingLeft}
      </Typography>
      <Typography
        onClick={goto}
        sx={{ flex: "1 1 20%", color: "#0AA0F4" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        {headingRight}
      </Typography>
    </Toolbar>
  )
}

export default function AppTable({
  rows,
  headCells,
  isUser,
  flaggedpost,
  height,
  rowsPage,
  feedback,
  goto,
  headingTable,
  onClickButton,
  decline,
  onClickItem,
  noPagination,
  loading,
  count,
  clickNext,
  offsetParent,
  bid,
  openImage
}) {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(rowsPage || 20)

  React.useEffect(() => {
    if (count === 0) {
      setPage(0)
    }
  }, [count])
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    if (clickNext) {
      const offset =
        newPage === page + 1 ? offsetParent + 20 : offsetParent - 20
      clickNext(offset)
    }
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const emptyRows = 0

  const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const d = new Date();
  return (
    <Paper sx={{ width: "100%", mb: 2, borderRadius: 4, marginTop: 2 }}>
      {headingTable && <EnhancedTableToolbar headingLeft={headingTable} />}
      <TableContainer sx={{ padding: 1, height: height || "auto" }}>
        <Table
          sx={{ minWidth: 750, height: height || "auto" }}
          aria-labelledby="tableTitle"
          size={"medium"}
        >
          {loading && (
            <div className="loading">
              <CircularProgress style={{ width: 20, height: 20 }} />
            </div>
          )}
          <EnhancedTableHead
            headCells={headCells}
            goto={goto}
            feedback={feedback}
          />
          <TableBody>
            {rows?.map((row, index) => {
              return (
                <TableRow
                  hover
                  key={index}
                  className={onClickItem ? "c-pointer" : ""}
                  onClick={() => onClickItem(row?.id)}
                >
                  <TableCell padding="checkbox"></TableCell>
                  {headCells?.map((item, index) => {
                    if (index < headCells?.length - 1) {
                      return (
                        <TableCell component="th" scope="row" padding="">
                          {
                            isUser
                              ?
                              index === 0 ?
                                <div className="d-flex align-items-center">
                                  {
                                    row?.profile?.profile_photo
                                    &&
                                    <img
                                      onClick={() =>
                                        openImage(
                                          row?.profile?.profile_photo
                                            ? row?.profile?.profile_photo
                                            : "https://cdn.dribbble.com/users/901433/screenshots/6214144/red_path.jpg?compress=1&resize=400x300&vertical=top"
                                        )
                                      }
                                      src={
                                        row?.profile?.profile_photo
                                          ? row?.profile?.profile_photo
                                          : "https://cdn.dribbble.com/users/901433/screenshots/6214144/red_path.jpg?compress=1&resize=400x300&vertical=top"
                                      }
                                      className={"qrcodeimage"}
                                      style={{ cursor: "pointer" }}
                                    />
                                  }
                                  {row?.profile?.[item?.id]}
                                </div>
                                : index === 1 ? row?.[item?.id] : item?.id === 'favorite_food_photo' || item?.id === 'ad_image_photo' ?
                                  <img
                                    onClick={() =>
                                      openImage(
                                        row?.profile?.[item?.id]
                                          ? row?.profile?.[item?.id]
                                          : "https://cdn.dribbble.com/users/901433/screenshots/6214144/red_path.jpg?compress=1&resize=400x300&vertical=top"
                                      )
                                    }
                                    src={
                                      row?.profile?.[item?.id]
                                        ? row?.profile?.[item?.id]
                                        : "https://cdn.dribbble.com/users/901433/screenshots/6214144/red_path.jpg?compress=1&resize=400x300&vertical=top"
                                    }
                                    className={"adimage"}
                                    style={{ cursor: "pointer" }}
                                  />
                                  : row?.profile?.[item?.id]
                              :
                              bid ?
                                item?.id === "amount" ? "$" + row[item?.id] :
                                  item?.id === "month" ? month[row[item?.id] - 1] :
                                    item?.id === "user_name" ? row?.user?.profile?.['name'] :
                                      item?.id === "user_email" ? row?.user?.['email'] :
                                        item?.id === "target_user_name" ? row?.target_user?.profile?.['name'] :
                                          item?.id === "target_user_email" ? row?.target_user?.['email'] :
                                            item?.id === "is_paid" ? row[item?.id] ? "Yes" : "No" :
                                              item?.id === "created_at" ? moment(row[item?.id]).format("LLL") :
                                                row[item?.id]
                                :
                                flaggedpost ?
                                  index === 2 ?
                                    row?.[item?.id]
                                    :
                                    row?.post[item?.id]
                                  :
                                  row?.[item?.id]
                          }
                        </TableCell>
                      )
                    } else return null
                  })}
                  {
                    !headCells[headCells?.length - 1]?.noAction &&
                    <TableCell align="left" width={'30%'} scope="row" sx={{ width: 200 }}>
                      <Grid
                        container
                        direction={'row'}
                        justifyContent={"center"}
                        style={{ marginTop: 5 }}
                        spacing={2}
                      >
                        <IconButton
                          className="primaryButton "
                          disabled={
                            (decline && row?.decline) ||
                            (headCells[headCells?.length - 1]?.buttonText ===
                              "Mark As Complete" &&
                              row?.solved)
                          }
                          style={{
                            // marginRight: 10,
                            width: 80,
                            opacity:
                              (decline && row?.decline) ||
                                (headCells[headCells?.length - 1]?.buttonText ===
                                  "Mark As Complete" &&
                                  row?.solved)
                                ? 0.4
                                : 1
                          }}
                          onClick={() => {
                            if (
                              headCells[headCells?.length - 1]?.buttonText ===
                              "Mark As Complete" &&
                              row?.solved
                            ) {
                              console.log("completed")
                            } else {
                              onClickButton(
                                row?.id,
                                row?.profile?.name
                              )
                            }
                          }}
                        >
                          <Typography>
                            {headCells[headCells?.length - 1]?.buttonText}
                          </Typography>
                        </IconButton>
                        {
                          headCells?.[headCells?.length - 1]?.flagUser &&
                          <IconButton
                            className="primaryButton"
                            style={{
                              marginTop: 10,
                              width: 80,
                            }}
                            onClick={() => {
                              
                            }}
                          >
                            <Typography>
                              {
                                "Flag"
                              }
                            </Typography>
                          </IconButton>
                        }
                      </Grid>
                    </TableCell>
                  }
                </TableRow>
              )
            })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!noPagination && (
        <TablePagination
          rowsPerPageOptions={[20]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  )
}
