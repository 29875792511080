import * as React from "react"
import {
  Grid,
  Container,
  Button,
  Paper,
  Typography,
  CircularProgress
} from "@mui/material"
import { DeleteModal, Layout } from "../../components"
import AppTable from "../../components/AppTable"
import { useContext } from "react"
import AppContext from "../../Context"
import { useState } from "react"
import {
  addDocument,
  deleteDocument,
  deleteUser,
  getDocuments,
  updateUser
} from "../../api/admin"
import { useEffect } from "react"
import { getError } from "../../constants/error"
import { useNavigate } from "react-router-dom"

const headCells1 = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name"
  },
  {
    id: "link",
    link: true,
    numeric: false,
    disablePadding: true,
    label: "Link"
  },
  {
    id: "action",
    numeric: false,
    buttonText: "View",
    buttonText1: "Delete",
    request: true,
    disablePadding: false,
    label: "Action"
  }
]
function DocumentsContent() {
  const fileInput = React.useRef(null)
  const navigate = useNavigate()
  const [state, setState] = useState({
    filteredList: [],
    documents: [],
    open: "",
    accessibility: "",
    file: null,
    link: "",
    name: "",
    loading: false,
    limit: 5,
    page: 0,
    count: 0,
    visible: false,
    docname: "",
    id: "",
    loadingDelete: false
  })
  const {
    filteredList,
    accessibility,
    name,
    link,
    file,
    open,
    loading,
    documents,
    page,
    limit,
    count,
    visible,
    docname,
    id,
    loadingDelete
  } = state
  const handleChange = (name, value) => {
    setState(pre => ({ ...pre, [name]: value }))
  }

  useEffect(() => {
    getData(0)
  }, [])

  const getData = paged => {
    const payload = `?limit=${limit}&offset=${paged}`
    _getDocuments(payload)
  }

  const _getDocuments = async payload => {
    try {
      handleChange("documents", [])
      handleChange("loading", true)
      const queryParams = payload ? payload : ""
      const token = localStorage.getItem("token")
      const res = await getDocuments(queryParams, token)
      handleChange("documents", res?.data?.results)
      handleChange("filteredList", res?.data?.results)
      handleChange("count", res?.data?.count)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      getError(error, navigate)
    }
  }

  const filtered = value => {
    const payload = `?limit=${limit}&offset=${0}&search=${value}`
    _getDocuments(payload)
    handleChange("count", 0)
    handleChange("page", 0)
  }

  const _updateUser = async (id, flag) => {
    try {
      const token = localStorage.getItem("token")
      const payload = {
        flag
      }
      await updateUser(id, payload, token)
      getData(0)
    } catch (error) {
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const _deleteDocument = async () => {
    try {
      handleChange("loadingDelete", true)
      const token = localStorage.getItem("token")
      await deleteDocument(id, token)
      handleChange("loadingDelete", false)
      getData(0)
      onClose()
    } catch (error) {
      handleChange("loadingDelete", false)
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const onClose = () => {
    handleChange("visible", false)
    handleChange("id", "")
    handleChange("docname", "")
  }

  const onDeleteModal = (id, name) => {
    handleChange("visible", true)
    handleChange("id", id)
    handleChange("docname", name)
  }

  const handleClose = value => {
    handleChange("open", value)
  }

  function onResumeClick(link) {
    window.open(link)
  }

  const _addDocument = async () => {
    try {
      const token = localStorage.getItem("token")
      const formData = new FormData()
      formData.append("name", name)
      formData.append("file", file)
      formData.append("accessibility", accessibility)
      formData.append("link", link)
      await addDocument(formData, token)
      getData(0)
      handleChange("open", false)
    } catch (error) {
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const clickNext = newPage => {
    getData(newPage)
    handleChange("page", newPage)
  }

  return (
    <Layout>
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container>
          <div class="search">
            <span class="form-element">
              <span class="fa fa-search"></span>
              <input
                placeholder="Search documents"
                onChange={value => filtered(value.target.value)}
              />
            </span>
          </div>
        </Grid>
        <Grid
          container
          justifyContent={"space-between"}
          className="mt-3"
          alignItems={"center"}
        >
          <Typography className="text_primary font-weight-bold">
            List Of All The Documents
          </Typography>
          <Button onClick={() => handleClose(true)} className="primaryButton">
            Add Document
          </Button>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={open ? 8 : 12} xs={12}>
            {documents && (
              <AppTable
                clickNext={clickNext}
                loading={loading}
                count={count}
                offsetParent={page}
                rows={filteredList}
                flagAction={_updateUser}
                deleteAction={onDeleteModal}
                onClickButton={onResumeClick}
                document
                rowsPage={15}
                headingLeft={"User activity"}
                headingRight={""}
                headCells={headCells1}
              />
            )}
          </Grid>
          {open && (
            <Grid item md={4} xs={12}>
              <Paper sx={{ mt: 2, width: "100%" }}>
                <div className={"zipModal"}>
                  <Grid item xs={12} className={"mt-3"}>
                    <select
                      className="zipcode"
                      onClick={value =>
                        handleChange("accessibility", value.target.value)
                      }
                    >
                      <option value={""}>Select Accessibility</option>
                      <option value={"Area Manager"}>Area Manager</option>
                      <option value={"General Manager"}>General Manager</option>
                      <option value={"Staff"}>Staff</option>
                      <option value={"Contract Maintenance"}>
                        Contract Maintenance
                      </option>
                      <option value={"House Keeping"}>House Keeping</option>
                      <option value={"Admin"}>Admin</option>
                    </select>
                  </Grid>
                  <Grid item xs={12} className={"mt-3 mb-3"}>
                    <input
                      className={"zipcode"}
                      placeholder="Name"
                      onChange={value =>
                        handleChange("name", value.target.value)
                      }
                    />
                  </Grid>
                  <div className="d-flex justify-content-center mt-4 mb-4">
                    <Button
                      className="outlineButton width150"
                      component="label"
                    >
                      Browse File
                      <input
                        type="file"
                        onChange={value =>
                          handleChange("file", value.target.files[0])
                        }
                        hidden
                      />
                    </Button>
                  </div>
                  <Grid item xs={12}>
                    <input
                      className={"zipcode"}
                      placeholder="Link"
                      onChange={value =>
                        handleChange("link", value.target.value)
                      }
                    />
                  </Grid>

                  <div className="d-flex justify-content-center mt-4">
                    <Button
                      disabled={!name || !link || !file || !accessibility}
                      className="primaryButton width150"
                      onClick={_addDocument}
                    >
                      {loading ? <CircularProgress /> : "Add"}
                    </Button>
                  </div>
                </div>
              </Paper>
            </Grid>
          )}
        </Grid>
      </Container>
      <DeleteModal
        visible={visible}
        onPress={_deleteDocument}
        onClose={onClose}
        loading={loadingDelete}
        description={
          "Are you sure you want to delete this document " + `"` + docname + `"`
        }
      />
    </Layout>
  )
}

export default function Documents() {
  return <DocumentsContent />
}
