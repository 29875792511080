import { API } from "./"
import { API_URL } from "./config"

export const getPayments = token => {
  return API.get(`api/v1/payments/list_payments/`, token)
}

export const getBids = token => {
  return API.get(`api/v1/bid/`, token)
}

export const getCountries = token => {
  return API.get(`api/v1/countries`, token)
}

export const getAllUsers = (payload, token) => {
  return API.get(`api/v1/users/${payload}`, token)
}

export const getAllFeedbacks = (token) => {
  return API.get(`api/v1/user_feedback/`, token)
}

export const addUser = (payload, token) => {
  return API.post(`api/v1/admin/users/`, payload, token)
}

export const getZipcodes = (payload, token) => {
  return API.get(`api/v1/admin/zipcodes/${payload}`, token)
}

export const updateZipcodes = (id, payload, token) => {
  return API.patch(`api/v1/admin/zipcodes/${id}/`, payload, token)
}

export const updateUser = (id, payload, token) => {
  return API.patch(`api/v1/admin/users/${id}/`, payload, token)
}

export const deleteZipcodes = (id, token) => {
  return API.delete(`api/v1/admin/zipcodes/${id}/`, {}, token)
}

export const deleteOrder = (id, token) => {
  return API.delete(`api/v1/admin/orders/${id}/`, {}, token)
}

export const deleteUser = (id, token) => {
  return API.delete(`api/v1/users/${id}/`, {}, token)
}

export const addZipcodes = (payload, token) => {
  return API.post(`api/v1/admin/zipcodes/`, payload, token)
}

export const getFlaggedPost = (payload, token) => {
  return API.get(`api/v1/flagged-posts${payload}`, token)
}

export const acceptRequest = (payload, token) => {
  return API.post(`api/v1/admin/login-requests/`, payload, token)
}

export const declineRequest = (id, payload, token) => {
  return API.patch(`api/v1/admin/login-requests/${id}/`, payload, token)
}

export const getOrderDetails = (id, token) => {
  return API.get(`api/v1/admin/orders/${id}/`, token)
}

export const getQRCodes = (payload, token) => {
  return API.get(`api/v1/admin/qr-codes/${payload}`, token)
}

export const getAllQRCodes = (payload, token) => {
  return API.get(`api/v1/admin/all-qr-codes/${payload}`, token)
}

export const getOldQRCodes = (payload, token) => {
  return API.get(`api/v1/admin/old-qr-scans/${payload}`, token)
}

export const getDocuments = (payload, token) => {
  return API.get(`api/v1/admin/documents/${payload}`, token)
}

export const addDocument = (payload, token) => {
  return API.post(`api/v1/admin/documents/`, payload, token)
}

export const deleteDocument = (id, token) => {
  return API.delete(`api/v1/admin/documents/${id}/`, {}, token)
}

export const addCategory = (payload, token) => {
  return API.post(`api/v1/admin/categories/`, payload, token)
}

export const getCategory = token => {
  return API.get(`api/v1/admin/categories/`, token)
}

export const addLocation = (payload, token) => {
  return API.post(`api/v1/admin/locations/`, payload, token)
}

export const deleteLocation = (id, token) => {
  return API.delete(`api/v1/admin/locations/${id}/`, {}, token)
}

export const deleteHotel = (id, token) => {
  return API.delete(`api/v1/hotels/${id}/`, {}, token)
}

export const updateHotel = (id, payload, token) => {
  return API.patch(`api/v1/hotels/${id}/`, payload, token)
}

export const getLocation = (payload, token) => {
  return API.get(`api/v1/admin/locations/${payload}`, token)
}

export const addChecklist = (payload, token) => {
  return API.post(`api/v1/admin/checklists/`, payload, token)
}

export const deleteChecklist = (id, token) => {
  return API.delete(`api/v1/admin/checklists/${id}/`, {}, token)
}

export const getChecklist = token => {
  return API.get(`api/v1/admin/checklists/`, token)
}

export const addTools = (payload, token) => {
  return API.post(`api/v1/admin/tools/`, payload, token)
}

export const addHotel = (payload, token) => {
  return API.post(`api/v1/hotels/`, payload, token)
}

export const deleteTools = (id, token) => {
  return API.delete(`api/v1/admin/tools/${id}/`, {}, token)
}

export const getTools = token => {
  return API.get(`api/v1/admin/tools/`, token)
}

export const generateQR = (payload, token) => {
  return API.post(`api/v1/admin/locations/generate/`, payload, token)
}

export const getHotelsDropdown = token => {
  return API.get(`api/v1/admin/hotels/dropdown/`, token)
}

export const getLocationDropdown = token => {
  return API.get(`api/v1/all-locations/`, token)
}

export const getAccessTypes = token => {
  return API.get(`api/v1/access-types/`, token)
}

export const getAllIssues = (payload, token) => {
  return API.get(`api/v1/admin/all-issues/${payload}`, token)
}

export const markAsCompleteIssue = (id, payload, token) => {
  return API.patch(`api/v1/admin/all-issues/${id}/`, payload, token)
}
