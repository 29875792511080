import * as React from "react"
import { Grid, Container, Button, Paper } from "@mui/material"
import { Layout } from "../../components"
import AppTable from "../../components/AppTable"
import AppContext from "../../Context"
import { useContext } from "react"
import { useState } from "react"
import { useEffect } from "react"
import {
  acceptRequest,
  declineRequest,
  deleteOrder,
  getFlaggedPost
} from "../../api/admin"
import { useNavigate } from "react-router-dom"
import { getError } from "../../constants/error"

const headCells1 = [
 
  {
    id: "food_name",
    numeric: false,
    disablePadding: false,
    label: "Post Food Name"
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Post Description"
  },
  {
    id: "reason",
    numeric: false,
    disablePadding: true,
    label: "Reason"
  },
  {
    id: "action",
    numeric: false,
    request: true,
    buttonText: "Remove",
    buttonText1: "Accept",
    disablePadding: false,
    label: "Action"
  }
]
function FlaggedPostContent() {
  // const { requests, _getFlaggedPost } = useContext(AppContext)
  const navigate = useNavigate()
  const [state, setState] = useState({
    filteredList: [],
    flaggedpost: [],
    selectRole: false,
    loading: false,
    selectedID: "",
    limit: 20,
    page: 0,
    count: 0
  })
  const {
    filteredList,
    loading,
    selectedID,
    selectRole,
    page,
    limit,
    flaggedpost,
    count
  } = state
  const handleChange = (name, value) => {
    setState(pre => ({ ...pre, [name]: value }))
  }

  const _selectRole = id => {
    handleChange("selectRole", true)
    handleChange("selectedID", id)
  }

  useEffect(() => {
    getData(0)
  }, [])

  const getData = paged => {
    const payload = `?decline=False&limit=${limit}&offset=${paged}`
    _getFlaggedPost(payload)
  }

  const _getFlaggedPost = async payload => {
    try {
      handleChange("flaggedpost", [])
      handleChange("loading", true)
      const queryParams = payload ? payload : ""
      const token = localStorage.getItem("token")
      const res = await getFlaggedPost(queryParams, token)
      handleChange("flaggedpost", res?.data?.results)
      handleChange("filteredList", res?.data?.results)

      handleChange("count", res?.data?.count)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      getError(error, navigate)
    }
  }

  const filtered = value => {
    if (value) {
      const re = new RegExp(value, "i")
      var filtered = flaggedpost?.filter(entry =>
        Object.values(entry).some(
          val => typeof val === "string" && val.match(re)
        )
      )
      handleChange("filteredList", filtered)
    } else {
      handleChange("filteredList", flaggedpost)
    }
  }

  const _acceptRequest = async role => {
    try {
      const token = localStorage.getItem("token")
      const payload = {
        role,
        login_request: selectedID,
        password: generatePassword()
      }
      await acceptRequest(payload, token)
      handleChange("selectedID", "")
      getData(0)
      handleChange("selectRole", false)
    } catch (error) {
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  function generatePassword() {
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = ""
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n))
    }
    return retVal
  }

  const _declineRequest = async id => {
    try {
      const token = localStorage.getItem("token")
      const payload = {
        decline: true
      }
      await declineRequest(id, payload, token)
      getData(0)
    } catch (error) {
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const clickNext = newPage => {
    getData(newPage)
    handleChange("page", newPage)
  }

  console.log("filteredList", filteredList)
  return (
    <Layout>
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        {/* <Grid container>
          <div class="search">
            <span class="form-element">
              <span class="fa fa-search"></span>
              <input
                placeholder="SEARCH FLAGGED POSTS"
                onChange={value => filtered(value.target.value)}
              />
            </span>
          </div>
        </Grid> */}
        <Grid container spacing={2}>
          <Grid item md={selectRole ? 8 : 12} xs={12}>
            {flaggedpost && (
              <AppTable
                rows={filteredList}
                deleteAction={_selectRole}
                onClickButton={_declineRequest}
                decline
                flaggedpost
                count={count}
                loading={loading}
                offsetParent={page}
                clickNext={clickNext}
                headCells={headCells1}
              />
            )}
          </Grid>
          {selectRole && (
            <Grid item md={4} xs={12}>
              <Paper sx={{ mt: 2, width: "100%" }}>
                <div className={"zipModal"}>
                  <Grid item xs={12} className={"mt-3"}>
                    <select
                      className="zipcode"
                      onChange={value => _acceptRequest(value.target.value)}
                    >
                      <option value={""}>Select Role</option>
                      <option value={"Area Manager"}>Area Manager</option>
                      <option value={"General Manager"}>General Manager</option>
                      <option value={"Staff"}>Staff</option>
                      <option value={"Contract Maintenance"}>
                        Contract Maintenance
                      </option>
                      <option value={"House Keeping"}>House Keeping</option>
                      <option value={"Admin"}>Admin</option>
                    </select>
                  </Grid>
                </div>
              </Paper>
            </Grid>
          )}
        </Grid>
      </Container>
    </Layout>
  )
}

export default function FlaggedPost() {
  return <FlaggedPostContent />
}
