import * as React from "react"
import {
  Grid,
  Container,
  Box,
  Tabs,
  Tab,
  Button,
  Dialog,
  CircularProgress
} from "@mui/material"
import {
  AllHotels,
  AllLocation,
  DeleteModal,
  Layout,
  Tools
} from "../../components"
import AppTable from "../../components/AppTable"
import AppContext from "../../Context"
import { useContext } from "react"
import { useNavigate } from "react-router-dom"
import {
  addCategory,
  addChecklist,
  addHotel,
  addLocation,
  addTools,
  deleteChecklist,
  deleteHotel,
  deleteLocation,
  deleteTools,
  generateQR,
  getAccessTypes,
  getLocation
} from "../../api/admin"
import { getError } from "../../constants/error"

function LocationContent() {
  const {
    categories,
    _getCategory,
    // _getLocation,
    // locations,
    checklists,
    _getChecklist,
    _getTools,
    tools,
    _getQRCodes,
    hotels,
    hotelsDropdown,
    _getAllHotels
  } = useContext(AppContext)
  const navigate = useNavigate()
  const [state, setState] = React.useState({
    value: "1",
    categoryTitle: "",
    selectedTitle: "",
    titleLocation: "",
    accessibility: [],
    accessibility1: "",
    titleTool: "",
    titleChecklist: "",
    toolChecklist: "",
    locationChecklist: "",
    hours: 0,
    minutes: 0,
    addnew: false,
    addnewTool: false,
    openCat: false,
    loadingQR: false,
    toolQR: "",
    hotel: "",
    locationQR: "",
    openQRModal: false,
    openHot: false,
    hotel: "",
    titleHotel: "",
    description: "",
    selectedLocation: "",
    selectedLocationID: "",
    locations: [],
    filteredList: [],
    limit: 20,
    count: 0,
    page: 0,
    rowsPage: 20,
    addnewHotel: false,
    visible: false,
    name: "",
    id: "",
    deleteType: "",
    loadingDelete: false,
    accessTypes: []
  })

  const {
    value,
    addnew,
    addnewTool,
    openCat,
    categoryTitle,
    selectedTitle,
    titleLocation,
    accessibility,
    accessibility1,
    titleTool,
    hours,
    minutes,
    titleChecklist,
    toolChecklist,
    locationChecklist,
    openQRModal,
    loadingQR,
    toolQR,
    locationQR,
    hotel,
    titleHotel,
    description,
    selectedLocation,
    selectedLocationID,
    openHot,
    addnewHotel,
    filteredList,
    locations,
    limit,
    page,
    count,
    rowsPage,
    visible,
    name,
    deleteType,
    id,
    loadingDelete,
    accessTypes
  } = state

  const handleChange = (name, value) => {
    setState(pre => ({ ...pre, [name]: value }))
  }

  React.useEffect(() => {
    getData(true)
    _getAccessTypes()
  }, [])

  const getData = (resetPage, paged) => {
    const payload = `?limit=${limit}&offset=${resetPage ? 0 : paged}`
    _getLocation(payload, resetPage)
  }

  const _getLocation = async payload => {
    try {
      handleChange("loading", true)
      const queryParams = payload ? payload : ""
      const token = localStorage.getItem("token")
      const res = await getLocation(queryParams, token)
      handleChange("locations", res?.data?.results)
      handleChange("count", res?.data?.count)
      handleChange("filteredList", res?.data?.results)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      getError(error)
    }
  }

  const _getAccessTypes = async () => {
    try {
      handleChange("loading", true)
      const token = localStorage.getItem("token")
      const res = await getAccessTypes(token)
      console.log("res", res?.data)
      handleChange("accessTypes", res?.data?.results)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      getError(error)
    }
  }

  const _addCategory = async () => {
    try {
      const token = localStorage.getItem("token")
      const payload = {
        title: categoryTitle
      }
      await addCategory(payload, token)
      handleChange("openCat", false)
      handleChange("categoryTitle", "")
      handleChange("selectedTitle", categoryTitle)
      _getCategory()
    } catch (error) {
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const _addLocation = async () => {
    try {
      const token = localStorage.getItem("token")
      const payload = {
        title: titleLocation,
        category: selectedTitle,
        accessibility: accessibility
      }
      await addLocation(payload, token)
      handleChange("addnew", false)
      handleChange("selectedTitle", "")
      handleChange("titleLocation", "")
      handleChange("accessibility", [])
      _getLocation()
    } catch (error) {
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }
  const _addTools = async () => {
    try {
      const token = localStorage.getItem("token")
      var formdata = new FormData()
      formdata.append("title", titleTool)
      formdata.append(
        "time",
        `${hours < 10 ? "0" + hours : hours}:${
          minutes < 10 ? "0" + minutes : minutes
        }`
      )
      formdata.append("accessibility", accessibility1)
      console.log("payload", formdata)
      await addTools(formdata, token)
      handleChange("addnew", false)
      handleChange("titleTool", "")
      handleChange("hours", 0)
      handleChange("minutes", 0)
      handleChange("addnewTool", false)
      _getTools()
    } catch (error) {
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const _addHotel = async () => {
    try {
      const token = localStorage.getItem("token")
      var formdata = new FormData()
      formdata.append("name", titleHotel)
      formdata.append("description", description)
      formdata.append("locations", selectedLocationID)
      console.log("payload", formdata)
      await addHotel(formdata, token)
      handleChange("addnewHotel", false)
      handleChange("titleHotel", "")
      handleChange("description", "")
      handleChange("selectedLocation", "")
      handleChange("selectedLocationID", "")
      _getAllHotels()
    } catch (error) {
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const _addChecklist = async () => {
    try {
      const token = localStorage.getItem("token")
      var formdata = new FormData()
      formdata.append("items[0]title", titleChecklist)
      formdata.append("tool", toolChecklist)
      formdata.append("location", locationChecklist)
      console.log("payload", formdata)
      await addChecklist(formdata, token)
      handleChange("titleChecklist", "")
      handleChange("toolChecklist", "")
      handleChange("locationChecklist", "")
      _getChecklist()
    } catch (error) {
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const _deleteHotel = async id => {
    try {
      const token = localStorage.getItem("token")
      await deleteHotel(id, token)
      _getAllHotels()
    } catch (error) {
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const _deleteLocation = async () => {
    try {
      handleChange("loadingDelete", true)
      const token = localStorage.getItem("token")
      await deleteLocation(id, token)
      getData(true)
      onClose()
      handleChange("loadingDelete", false)
      alert("Location has been deleted")
    } catch (error) {
      handleChange("loadingDelete", false)
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const _deleteChecklist = async () => {
    try {
      handleChange("loadingDelete", true)
      const token = localStorage.getItem("token")
      await deleteChecklist(id, token)
      _getChecklist()
      onClose()
      handleChange("loadingDelete", false)
      alert("Checklist has been deleted")
    } catch (error) {
      handleChange("loadingDelete", false)
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const _deleteTools = async () => {
    try {
      handleChange("loadingDelete", true)
      const token = localStorage.getItem("token")
      await deleteTools(id, token)
      _getTools()
      onClose()
      handleChange("loadingDelete", false)
      alert("Tool has been deleted")
    } catch (error) {
      handleChange("loadingDelete", false)
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const _generateQR = async () => {
    try {
      handleChange("loadingQR", true)
      const token = localStorage.getItem("token")
      const payload = {
        location: locationQR,
        tool: toolQR,
        hotel
      }
      await generateQR(payload, token)
      handleChange("loadingQR", false)
      handleChange("openQRModal", false)
      _getQRCodes()
    } catch (error) {
      const errorText = Object.values(error?.response?.data)
      handleChange("loadingQR", false)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const filtered = value => {
    const payload = `?limit=${limit}&offset=${0}&search=${value}`
    _getLocation(payload, true)
    handleChange("count", 0)
    handleChange("page", 0)
    // if (value) {
    //   const re = new RegExp(value, "i")
    //   var filtered = locations?.filter(entry =>
    //     Object.values(entry).some(
    //       val => typeof val === "string" && val.match(re)
    //     )
    //   )
    //   handleChange("filteredList", filtered)
    // } else {
    //   handleChange("filteredList", locations)
    // }
  }

  const clickNext = newPage => {
    getData(false, newPage)
    handleChange("page", newPage)
  }

  const onDeleteModal = (id, name, deleteType) => {
    handleChange("visible", true)
    handleChange("id", id)
    handleChange("name", name)
    handleChange("deleteType", deleteType)
  }

  const onClose = () => {
    handleChange("visible", false)
    handleChange("id", "")
    handleChange("name", "")
    handleChange("deleteType", "")
  }

  return (
    <Layout>
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container>
          <div class="search">
            <span class="form-element">
              <span class="fa fa-search"></span>
              <input
                placeholder="SEARCH HOT KEYWORDS PANEL"
                onChange={value => filtered(value.target.value)}
              />
            </span>
          </div>
        </Grid>
        {/* <Grid container justifyContent={"flex-end"} className="mt-3">
          <Button
            onClick={() =>
              handleChange(
                value === "3"
                  ? "addnewHotel"
                  : value === "1"
                  ? "addnew"
                  : "addnewTool",
                true
              )
            }
            className="primaryButton"
          >
            {value === "3"
              ? "Add New Hotel"
              : value === "1"
              ? "Add New Location"
              : "Add New Tool"}
          </Button>
          {value !== "3" && (
            <Button
              onClick={() => handleChange("openQRModal", true)}
              className="primaryButton ml-3"
            >
              Generate QR Code
            </Button>
          )}
        </Grid> */}
        <Box sx={{ width: "100%", mt: 2 }}>
          <Tabs
            value={value}
            onChange={(e, value) => handleChange("value", value)}
            classes={{ indicator: "indicator", root: "tabs" }}
            aria-label="secondary tabs example"
          >
            <Tab
              value="1"
              className="tabs"
              classes={{ selected: "activeTab" }}
              label="Hot Keywords panel"
            />
            {/* <Tab
              value="2"
              classes={{ selected: "activeTab" }}
              className="tabs"
              label="Tools"
            /> */}
          </Tabs>
        </Box>
        {value === "1" && (
          <AllLocation
            handleChange={handleChange}
            _addCategory={_addCategory}
            categories={categories}
            locations={filteredList}
            categoryTitle={categoryTitle}
            selectedTitle={selectedTitle}
            openCat={openCat}
            addnew={addnew}
            rowsPage={rowsPage}
            count={count}
            offsetParent={page}
            clickNext={clickNext}
            titleLocation={titleLocation}
            accessibility={accessibility}
            accessTypes={accessTypes}
            _addLocation={_addLocation}
            _deleteLocation={onDeleteModal}
            _deleteChecklist={onDeleteModal}
            checklists={checklists}
            _addChecklist={_addChecklist}
            tools={tools}
            titleChecklist={titleChecklist}
            toolChecklist={toolChecklist}
            locationChecklist={locationChecklist}
          />
        )}
        {value === "2" && (
          <Tools
            addnew={addnewTool}
            locations={locations}
            tools={tools}
            _deleteLocation={onDeleteModal}
            _deleteTools={onDeleteModal}
            _addTools={_addTools}
            titleTool={titleTool}
            rowsPage={rowsPage}
            count={count}
            offsetParent={page}
            clickNext={clickNext}
            accessibility1={accessibility1}
            hours={hours}
            minutes={minutes}
            handleChange={handleChange}
          />
        )}
        <Dialog
          onClose={() => handleChange("openQRModal", false)}
          open={openQRModal}
        >
          <div className={"zipModal"}>
            <Grid
              container
              justifyContent={"space-between"}
              className={"mt-3"}
              spacing={2}
            >
              <select
                className="zipcode"
                value={locationQR}
                onChange={value =>
                  handleChange("locationQR", value.target.value)
                }
              >
                <option value={""}>Select Location</option>
                {locations?.map((location, index) => (
                  <option key={index} value={location?.id}>
                    {location?.title}
                  </option>
                ))}
              </select>
              <select
                className="zipcode mt-2"
                value={toolQR}
                onChange={value => handleChange("toolQR", value.target.value)}
              >
                <option value={""}>Select Tool</option>
                {tools?.map((tool, index) => (
                  <option key={index} value={tool?.id}>
                    {tool?.title}
                  </option>
                ))}
              </select>
              <select
                className="zipcode mt-2"
                value={hotel}
                onChange={value => handleChange("hotel", value.target.value)}
              >
                <option value={""}>Select Hotel</option>
                {hotelsDropdown?.map((hotel, index) => (
                  <option key={index} value={hotel?.id}>
                    {hotel?.name}
                  </option>
                ))}
              </select>
            </Grid>
            <div className="d-flex justify-content-center mt-4">
              <Button
                disabled={!locationQR || !toolQR || !hotel}
                className="primaryButton mr-3 width150"
                onClick={_generateQR}
              >
                {loadingQR ? (
                  <CircularProgress
                    style={{ color: "#fff", width: 20, height: 20 }}
                  />
                ) : (
                  "Add"
                )}
              </Button>
            </div>
          </div>
        </Dialog>
        <DeleteModal
          visible={visible}
          onPress={
            deleteType === "location"
              ? _deleteLocation
              : deleteType === "tool"
              ? _deleteTools
              : _deleteChecklist
          }
          onClose={onClose}
          loading={loadingDelete}
          description={
            `Are you sure you want to delete this ${deleteType} ` +
            `"` +
            name +
            `"`
          }
        />
      </Container>
    </Layout>
  )
}

export default function Location() {
  return <LocationContent />
}
