import * as React from 'react'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'

export const MAIN = '/'
export const DASHBOARD = '/dashboard'
export const LOCATION = '/hot-keywords-panel'
export const FEEDBACKS = '/feedback'
export const USER = '/users'
export const DOCUMENTS = '/documents'
export const GENERATEQR = '/recipe-of-the-day'
export const FLAGGED_POST = '/flagged-posts'
export const ACTIVITY = '/payments'
export const ISSUES = '/bids'

export const SIDEBAR = (
  <React.Fragment>
    <ListItemButton>
      <ListItemText primary='Dashboard' />
    </ListItemButton>
    <ListItemButton>
      <ListItemText primary='Users' />
    </ListItemButton>
    <ListItemButton>
      <ListItemText primary='Zip Codes' />
    </ListItemButton>
    <ListItemButton>
      <ListItemText primary='Requests' />
    </ListItemButton>
    <ListItemButton>
      <ListItemText primary='Feedback' />
    </ListItemButton>
  </React.Fragment>
)
