import * as React from "react"
import { styled } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import MuiDrawer from "@mui/material/Drawer"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import List from "@mui/material/List"
import ProfileImage from "../../assets/images/profile-large.png"
import IconButton from "@mui/material/IconButton"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemText from "@mui/material/ListItemText"
import LOGO from "../../assets/svg/logo.svg"
import { useLocation, useNavigate } from "react-router-dom"
import AppContext from "../../Context"
import { useContext } from "react"
import { useEffect } from "react"
import { Divider } from "@mui/material"
import UsersIcon from "../../assets/svg/users.svg"
import LogoutIcon from "../../assets/svg/logout.svg"
import ActivityIcon from "../../assets/svg/activity.svg"
import LoginIcon from "../../assets/svg/login.svg"
import DashboardIcon from "../../assets/svg/Dashboard.svg"
import QRIcon from "../../assets/svg/qr.svg"

const drawerWidth = 280

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== "open"
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9)
      }
    })
  }
}))

function LayoutContent({ children }) {
  const navigate = useNavigate()
  const location = useLocation()
  const {
    user,
    setUser,
    _getDashboard,
    _getAllUsers,
    _getDocuments,
    _getRequests,
    _getQRCodes,
    _getOldQRCodes,
    _getCategory,
    _getChecklist,
    _getLocation,
    _getTools,
    _getAllHotels,
    _getHotelsDropdown
  } = useContext(AppContext)
  const [open, setOpen] = React.useState(true)
  useEffect(() => {
    const userData = localStorage.getItem("user")
    setUser(JSON.parse(userData))
    if (location.pathname === "/dashboard") {
      _getDashboard()
      _getRequests()
    } else if (location.pathname === "/requests") {
      _getRequests()
    } else if (location.pathname === "/users") {
      _getAllUsers()
      _getHotelsDropdown()
    } else if (location.pathname === "/qrcodes") {
      // _getQRCodes()
    } else if (location.pathname === "/documents") {
      _getDocuments()
    } else if (location.pathname === "/location") {
      _getLocation()
      _getTools()
      _getCategory()
      _getChecklist()
      _getHotelsDropdown()
    } else if (location.pathname === "/hotels") {
      // _getAllHotels()
    } else {
      _getOldQRCodes()
      _getQRCodes()
    }
  }, [])

  const handleListItemClick = (route, index) => {
    navigate(route)
  }
  const logout = () => {
    localStorage.removeItem("token")
    localStorage.removeItem("user")
    navigate("/")
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        className="drawerClass"
        classes={{ paper: "drawerClass" }}
        style={{
          height: "100vh"
        }}
        open={open}
      >
        <div>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              px: [1]
            }}
          >
            <Grid container className="mt-4 mb-4">
              {/* <img
                src={user?.customer?.photo || ProfileImage}
                className={'ProfileImage'}
              /> */}
              <div className="ml-3">
                  <div className="text_white">{"BiteMate Admin Panel"}</div>
              </div>
            </Grid>
          </Toolbar>
          <Grid container justifyContent={"center"}>
            <Divider className="dividerColor" />
          </Grid>
          {open && (
            <List component="nav">
              {/* <ListItemButton
                selected={location.pathname === "/dashboard"}
                onClick={() => handleListItemClick("/dashboard", 0)}
                className={
                  location.pathname === "/dashboard"
                    ? "listButtonActive"
                    : "listButton"
                }
              >
                <img src={DashboardIcon} className="iconDashboard" />
                <ListItemText primary="Dashboard" />
              </ListItemButton> */}
              <ListItemButton
                selected={location.pathname === "/users"}
                onClick={() => handleListItemClick("/users", 0)}
                className={
                  location.pathname === "/users"
                    ? "listButtonActive"
                    : "listButton"
                }
              >
                <img src={UsersIcon} className="iconDashboard" />
                <ListItemText primary="Users" />
              </ListItemButton>
              <ListItemButton
                selected={location.pathname === "/flagged-posts"}
                onClick={() => handleListItemClick("/flagged-posts", 1)}
                className={
                  location.pathname === "/flagged-posts"
                    ? "listButtonActive"
                    : "listButton"
                }
              >
                <img src={LoginIcon} className="iconDashboard" />
                <ListItemText primary="Flagged Posts" />
              </ListItemButton>
              <ListItemButton
                selected={location.pathname === "/hot-keywords-panel"}
                onClick={() => handleListItemClick("/hot-keywords-panel", 2)}
                className={
                  location.pathname === "/hot-keywords-panel"
                    ? "listButtonActive"
                    : "listButton"
                }
              >
                <img src={UsersIcon} className="iconDashboard" />
                <ListItemText primary="Hot keywords panel" />
              </ListItemButton>
              <ListItemButton
                selected={location.pathname === "/feedback"}
                onClick={() => handleListItemClick("/feedback", 3)}
                className={
                  location.pathname === "/feedback"
                    ? "listButtonActive"
                    : "listButton"
                }
              >
                <img src={UsersIcon} className="iconDashboard" />
                <ListItemText primary="Feedback" />
              </ListItemButton>
              {/* <ListItemButton
                selected={location.pathname === "/recipe-of-the-day"}
                onClick={() => handleListItemClick("/recipe-of-the-day", 2)}
                className={
                  location.pathname === "/recipe-of-the-day"
                    ? "listButtonActive"
                    : "listButton"
                }
              >
                <img src={QRIcon} className="iconDashboard" />
                <ListItemText primary="Recipe of the day" />
              </ListItemButton> */}
              <ListItemButton
                selected={location.pathname === "/payments"}
                onClick={() => handleListItemClick("/payments", 3)}
                className={
                  location.pathname === "/payments"
                    ? "listButtonActive"
                    : "listButton"
                }
              >
                <img src={ActivityIcon} className="iconDashboard" />
                <ListItemText primary="Payments " />
              </ListItemButton>
              <ListItemButton
                selected={location.pathname === "/bids"}
                onClick={() => handleListItemClick("/bids", 4)}
                className={
                  location.pathname === "/bids"
                    ? "listButtonActive"
                    : "listButton"
                }
              >
                <img src={ActivityIcon} className="iconDashboard" />
                <ListItemText primary="Bids" />
              </ListItemButton>
            </List>
          )}
        </div>

        <Grid container className="mb-4 ml-3 c-pointer">
          <img src={LogoutIcon} className="iconDashboard" />
          <p onClick={logout} className="text_white mt-3">
            Log Out
          </p>
        </Grid>
      </Drawer>
      <Box
        component="main"
        sx={{
          backgroundColor: theme =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto"
        }}
      >
        {children}
      </Box>
    </Box>
  )
}

export default function Layout({ children }) {
  return <LayoutContent children={children} />
}
