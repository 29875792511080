import * as React from "react"
import {
  Grid,
  Container,
  Button,
  Dialog,
  Typography,
  CircularProgress
} from "@mui/material"
import { DeleteModal, Layout } from "../../components"
import AppTable from "../../components/AppTable"
import { useContext } from "react"
import AppContext from "../../Context"
import { useState } from "react"
import {
  addUser,
  deleteUser,
  getBids,
  getCountries,
  markAsCompleteIssue,
  updateUser
} from "../../api/admin"
import { useEffect } from "react"
import { getError } from "../../constants/error"
import { useNavigate } from "react-router-dom"

const headCells1 = [
  {
    id: "title",
    numeric: false,
    disablePadding: true,
    label: "Title"
  },
  {
    id: "description",
    numeric: false,
    disablePadding: true,
    label: "Description"
  },
  {
    id: "amount",
    numeric: false,
    disablePadding: true,
    label: "Bid Amount in ($)"
  },
  {
    id: "month",
    numeric: false,
    disablePadding: true,
    label: "Bid Month"
  },
  {
    id: "user_name",
    numeric: false,
    disablePadding: true,
    label: "User Name"
  },
  {
    id: "user_email",
    numeric: false,
    disablePadding: true,
    label: "User Email"
  },
  {
    id: "target_user_name",
    numeric: false,
    disablePadding: true,
    label: "Taget User Name"
  },
  {
    id: "target_user_email",
    numeric: false,
    disablePadding: true,
    label: "Taget User Email"
  },
  {
    id: "is_paid",
    numeric: false,
    disablePadding: true,
    label: "Paid"
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: true,
    label: "Created"
  },
  {
    id: "actionComplete",
    numeric: false,
    noAction: true,
    disablePadding: false,
    buttonText: "Mark As Complete",
    label: "Action"
  }
]
function IssuesContent() {
  const { hotelsDropdown } = useContext(AppContext)
  const navigate = useNavigate()
  const [state, setState] = useState({
    filteredList: [],
    allUsers: [],
    open: "",
    name: "",
    role: "",
    hotel: "",
    email: "",
    password: "",
    loading: false,
    limit: 20,
    page: 0,
    count: 0,
    visible: false,
    username: "",
    id: "",
    loadingDelete: false,
    searchText: "",
    selectedCountry: "",
    countries: []
  })
  const {
    filteredList,
    allUsers,
    open,
    name,
    role,
    email,
    hotel,
    password,
    loading,
    page,
    limit,
    count,
    visible,
    username,
    id,
    loadingDelete,
    selectedCountry,
    searchText,
    countries
  } = state
  const handleChange = (name, value) => {
    setState(pre => ({ ...pre, [name]: value }))
  }

  useEffect(() => {
    getData(0)
  }, [])

  const getData = paged => {
    _getCountries()
    const payload = `?limit=${limit}&offset=${paged}&search=${searchText}`
    _getBids(payload)
  }

  const _getCountries = async () => {
    try {
      const token = localStorage.getItem("token")
      const res = await getCountries(token)
      handleChange("countries", res?.data)
    } catch (error) {
      getError(error)
    }
  }

  const _getBids = async payload => {
    try {
      handleChange("allUsers", [])
      handleChange("loading", true)
      const queryParams = payload ? payload : ""
      const token = localStorage.getItem("token")
      const res = await getBids(token)
      handleChange("allUsers", res?.data?.results)
      handleChange("filteredList", res?.data?.results)
      handleChange("count", res?.data?.count)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      getError(error, navigate)
    }
  }

  const filtered = value => {
    handleChange("searchText", value)
    const payload = `?limit=${limit}&offset=${0}&search=${value}`
    _getBids(payload)
    handleChange("count", 0)
    handleChange("page", 0)
  }

  const _updateUser = async (id, flag) => {
    try {
      const token = localStorage.getItem("token")
      const payload = {
        flag
      }
      await updateUser(id, payload, token)
      getData(0)
    } catch (error) {
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const _deleteUser = async () => {
    try {
      const payload = {
        solved: true
      }
      handleChange("loadingDelete", true)
      const token = localStorage.getItem("token")
      await markAsCompleteIssue(id, payload, token)
      getData(0)
      onClose()
      handleChange("loadingDelete", false)
    } catch (error) {
      handleChange("loadingDelete", false)
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${error?.response?.data}`)
    }
  }

  const handleClose = value => {
    handleChange("open", value)
  }

  const _addUser = async () => {
    try {
      handleChange("loading", true)
      const token = localStorage.getItem("token")
      const payload = {
        name,
        role,
        email,
        password,
        hotel
      }
      await addUser(payload, token)
      getData(0)
      handleClose(false)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const clickNext = newPage => {
    getData(newPage)
    handleChange("page", newPage)
  }

  const onClose = () => {
    handleChange("visible", false)
    handleChange("id", "")
    handleChange("username", "")
  }

  const onDeleteModal = (id, name) => {
    handleChange("visible", true)
    handleChange("id", id)
    handleChange("username", name)
  }

  return (
    <Layout>
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container>
          <div class="search">
            <span class="form-element">
              <span class="fa fa-search"></span>
              <input
                placeholder="Search Bids"
                onChange={value => filtered(value.target.value)}
              />
            </span>
          </div>
        </Grid>
        <Grid
          container
          justifyContent={"space-between"}
          className="mt-3"
          alignItems={"center"}
        >
          <Typography className="text_primary font-weight-bold">
            List Of All The Bids
          </Typography>
          <select placeholder="Select Country" onChange={(e) => handleChange("selectedCountry", e.target.value)} value={selectedCountry}>
            <option value={''} disabled>Select Country</option>
            {
              countries?.map((country, index) => (
                <option key={index} value={country?.id}>{country?.name}</option>
              ))
            }
          </select>
        </Grid>
        {allUsers && (
          <AppTable
            rows={filteredList}
            count={count}
            offsetParent={page}
            bid={true}
            loading={loading}
            openImage={handleClose}
            clickNext={clickNext}
            flagAction={_updateUser}
            onClickButton={onDeleteModal}
            // rowsPage={5}
            headingLeft={"User activity"}
            headingRight={""}
            headCells={headCells1}
          />
        )}
      </Container>
      <Dialog onClose={() => handleClose('')} open={open}>
        <div className={"zipModal"}>
          <img src={open} className="issueImg" />
        </div>
      </Dialog>
      <DeleteModal
        visible={visible}
        onPress={_deleteUser}
        onClose={onClose}
        buttonText={"Complete"}
        loading={loadingDelete}
        description={"Are you sure you want to compelete this issue "}
      />
    </Layout>
  )
}

export default function Issues() {
  return <IssuesContent />
}
