import * as React from "react"
import { Grid, Container, Typography } from "@mui/material"
import { Layout } from "../../components"
import AppTable from "../../components/AppTable"
import AppContext from "../../Context"
import { useState, useContext } from "react"
import moment from "moment"
import { getPayments, getQRCodes } from "../../api/admin"
import { getError } from "../../constants/error"
import { useNavigate } from "react-router-dom"

const headCells = [
  {
    id: "amount",
    numeric: false,
    disablePadding: false,
    label: "Amount"
  },
  {
    id: "currency",
    numeric: false,
    disablePadding: false,
    label: "Currency"
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description"
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status"
  },
  {
    id: "created",
    numeric: false,
    disablePadding: false,
    label: "created"
  },
  {
    id: "action",
    numeric: false,
    buttonText: "",
    noAction: true,
    disablePadding: false,
    label: ""
  }
]
function ActivityContent() {
  // const { QRCodes, OldQRCodes } = useContext(AppContext)
  const token = localStorage.getItem("token")
  const navigate = useNavigate()
  const [state, setState] = useState({
    filteredList: [],
    QRCodes: [],
    payments: [],
    count: 0,
    oldcount: 0,
    limit: 5,
    loading: false,
    oldloading: false,
    page: 0,
    oldpage: 0
  })
  const {
    filteredList,
    count,
    limit,
    QRCodes,
    payments,
    loading,
    oldloading,
    page,
    oldpage,
    oldcount
  } = state
  const handleChange = (name, value) => {
    setState(pre => ({ ...pre, [name]: value }))
  }

  React.useEffect(() => {
    _getPayments()
    getData1(0)
  }, [])

  const _getPayments = async payload => {
    try {
      handleChange("payments", [])
      handleChange("oldloading", true)
      const queryParams = payload ? payload : ""
      const res = await getPayments(token)
      handleChange("payments", res?.data)
      handleChange("oldcount", res?.data?.count)
      handleChange("oldloading", false)
    } catch (error) {
      handleChange("oldloading", false)
      getError(error, navigate)
    }
  }
  const _getQRCodes = async payload => {
    try {
      handleChange("loading", true)
      handleChange("QRCodes", [])
      handleChange("filteredList", [])
      const queryParams = payload ? payload : ""
      const res = await getQRCodes(queryParams, token)
      handleChange("QRCodes", res?.data?.results)
      handleChange("filteredList", res?.data?.results)
      handleChange("count", res?.data?.count)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      getError(error, navigate)
    }
  }

  const getOldData = newPage => {
    const payload = `?limit=${limit}&offset=${newPage}`
    _getPayments(payload)
    handleChange("oldpage", newPage)
  }
  const getData1 = newPage => {
    const payload = `?limit=${limit}&offset=${newPage}`
    _getQRCodes(payload)
    handleChange("page", newPage)
  }

  const filtered = value => {
    const payload = `?limit=${limit}&offset=${0}&search=${value}`
    _getQRCodes(payload, true)
    handleChange("count", 0)
    handleChange("page", 0)
  }

  const exportData1 = data => {
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(data)
    )}`
    const link = document.createElement("a")
    link.href = jsonString
    link.download = "data.json"

    link.click()
  }

  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType })

    const a = document.createElement("a")
    a.download = fileName
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true
    })
    a.dispatchEvent(clickEvt)
    a.remove()
  }

  const exportData = data => {
    // Headers for each column
    let headers = ["Name,Date,Time,Image"]

    // Convert users data to a csv
    let usersCsv = [data].reduce((acc, user) => {
      const { location, image, generated_on } = user
      acc.push(
        [
          location?.title,
          moment(generated_on).format("DD/MM/YYYY"),
          moment(generated_on).format("hh:mm A"),
          image
        ].join(",")
      )
      return acc
    }, [])

    const data1 = {
      name: "sss"
    }

    downloadFile({
      data: [...headers, ...usersCsv].join("\n"),
      fileName: "users.csv",
      fileType: "text/csv"
    })
  }

  return (
    <Layout>
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        {/* <Grid container>
          <div class="search">
            <span class="form-element">
              <span class="fa fa-search"></span>
              <input
                placeholder="SEARCH PAYMENT"
                onChange={value => filtered(value.target.value)}
              />
            </span>
          </div>
        </Grid> */}
        <Grid
          container
          justifyContent={"space-between"}
          className="mt-3"
          alignItems={"center"}
        >
          <Typography className="text_primary font-weight-bold">
            Payments
          </Typography>
        </Grid>
        {payments?.length > 0 && (
          <AppTable
            onClickButton={exportData}
            qrcode
            activity
            clickNext={getData1}
            count={count}
            noPagination
            offsetParent={page}
            loading={loading}
            rows={payments}
            headCells={headCells}
          />
        )}
        {/* <Grid
          container
          justifyContent={"space-between"}
          className="mt-3"
          spacing={2}
        >
          <Grid item md={6} xs={6}>
            <Grid
              container
              justifyContent={"space-between"}
              className="mt-3"
              alignItems={"center"}
            >
              <Typography className="text_primary font-weight-bold">
                Currently Active
              </Typography>
            </Grid>
            {QRCodes && (
              <AppTable
                qrcode
                count={count}
                offsetParent={page}
                rows={QRCodes}
                clickNext={getData1}
                loading={loading}
                headCells={headCells1}
              />
            )}
          </Grid>
          <Grid item md={6} xs={6}>
            <Grid
              container
              justifyContent={"space-between"}
              className="mt-3"
              alignItems={"center"}
            >
              <Typography className="text_primary font-weight-bold">
                Old QR Code Scans List
              </Typography>
            </Grid>
            {OldQRCodes && (
              <AppTable
                qrcode
                old
                clickNext={getOldData}
                loading={oldloading}
                offsetParent={oldpage}
                count={oldcount}
                rows={OldQRCodes}
                headCells={headCells2}
              />
            )}
          </Grid>
        </Grid> */}
      </Container>
    </Layout>
  )
}

export default function Activity() {
  return <ActivityContent />
}
