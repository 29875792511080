import React from "react"
import { Button, CircularProgress, Dialog, Grid } from "@mui/material"

export default function DeleteModal({
  visible,
  onClose,
  onPress,
  description,
  loading,
  buttonText
}) {
  return (
    <Dialog onClose={onClose} open={visible}>
      <div className={"zipModal"}>
        <Grid className={"mt-3"} spacing={2} style={{ textAlign: "center" }}>
          <h3>Confimation</h3>
          <div>{description}</div>
        </Grid>
        <div className="d-flex justify-content-center mt-4">
          <Button className="primaryButton mr-3 width150" onClick={onClose}>
            Cancel
          </Button>
          <Button className="primaryButton mr-3 width150" onClick={onPress}>
            {loading ? (
              <CircularProgress
                style={{ color: "#fff", width: 20, height: 20 }}
              />
            ) : (
              buttonText || "Delete"
            )}
          </Button>
        </div>
      </div>
    </Dialog>
  )
}
