import * as React from "react"
import { Grid, Container, Typography, Button, IconButton } from "@mui/material"
import { Layout } from "../../components"
import AppTable from "../../components/AppTable"
import AppContext from "../../Context"
import { useContext } from "react"
import { useState } from "react"
import { getError } from "../../constants/error"
import { getAllQRCodes, getQRCodes } from "../../api/admin"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

const headCells1 = [
  {
    id: "image",
    numeric: false,
    label: "QR Code"
  },
  {
    id: "location.title",
    numeric: false,
    disablePadding: false,
    label: "Location Name"
  },
  {
    id: "hotel",
    numeric: false,
    disablePadding: false,
    label: "Hotel Name"
  },
  {
    id: "generated_on",
    numeric: false,
    disablePadding: false,
    label: "Generated Time"
  },
  {
    id: "generated_on",
    numeric: false,
    disablePadding: true,
    label: "Generated Date"
  },
  {
    id: "action",
    numeric: false,
    buttonText: "Print",
    disablePadding: false,
    label: "Action"
  }
]
function GenerateQRContent() {
  const {
    _getHotelsDropdown,
    hotelsDropdown,
    _getLocationDropdown,
    locationDropdown
  } = useContext(AppContext)
  const navigate = useNavigate()
  const [state, setState] = useState({
    filteredList: [],
    QRCodes: [],
    page: 0,
    limit: 20,
    loading: false,
    count: 0,
    hotel: "",
    locationQR: "",
    locations: [],
    qrlist: [],
    searchText: ""
  })
  const {
    filteredList,
    QRCodes,
    page,
    limit,
    loading,
    count,
    locationQR,
    hotel,
    locations,
    searchText,
    qrlist
  } = state
  const handleChange = (name, value) => {
    setState(pre => ({ ...pre, [name]: value }))
  }

  useEffect(() => {
    getData(0)
    _getHotelsDropdown()
    _getLocationDropdown()
  }, [])

  const getData = paged => {
    const payload = `?limit=${limit}&offset=${paged}&search=${searchText}`
    const payload1 = `?hotel__name=${searchText}`
    _getQRCodes(payload)
    if (searchText) {
      _getAllQRCodes(payload1)
    }
  }

  const _getAllQRCodes = async payload => {
    try {
      handleChange("loading", true)
      const queryParams = payload ? payload : ""
      const token = localStorage.getItem("token")
      const res = await getAllQRCodes(queryParams, token)
      handleChange("qrlist", res?.data)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      getError(error, navigate)
    }
  }

  const _getQRCodes = async payload => {
    try {
      handleChange("loading", true)
      const queryParams = payload ? payload : ""
      const token = localStorage.getItem("token")
      const res = await getQRCodes(queryParams, token)
      handleChange("QRCodes", res?.data?.results)
      // handleChange("filteredList", res?.data?.results)

      handleChange("count", res?.data?.count)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      getError(error, navigate)
    }
  }

  const clickNext = newPage => {
    const paged = page + limit
    getData(newPage)
    handleChange("page", newPage)
  }

  const filtered = value => {
    handleChange("searchText", value)
    const payload = `?limit=${limit}&offset=${0}&search=${value}`
    const payload1 = `?hotel__name=${value}`
    _getQRCodes(payload, true)
    if (value) {
      _getAllQRCodes(payload1)
    } else if (!value) {
      handleChange("qrlist", [])
    }
    handleChange("count", 0)
    handleChange("page", 0)
    // if (value) {
    //   const re = new RegExp(value, "i")
    //   var filtered = QRCodes?.filter(entry =>
    //     Object.values(entry?.location).some(
    //       val => typeof val === "string" && val.match(re)
    //     )
    //   )
    //   handleChange("filteredList", filtered)
    // } else {
    //   handleChange("filteredList", QRCodes)
    // }
  }

  function printImage(item) {
    var win = window.open("about:blank", "_new")
    win.document.open()
    win.document.write(
      [
        `<html>
        <head>
        </head>
          <body onload="window.print()" onafterprint="window.close()">
          <div style="padding-top: 1px;display:block;">
            <div style="content: '';width:100%; margin-top:20px;">
              <div style="display: flex;flex-direction:column;align-items:center;justify-content:center;width: 100%;height:100%">
                  <img src="${item?.image}" width="400px" height="400px" />
                  <div style="width:320px">
                    <div style="display:flex">Hotel Name:${item?.hotel?.name} </div>
                    <div style="display:flex">Location Name:${item?.location?.title} </div>
                  </div>
              </div>
            </div>
          </div>
        </body>
        </html>`
      ].join("")
    )
    win.document.close()
  }
  function printAllImage() {
    var win = window.open("about:blank", "_new")
    win.document.open()
    win.document.write(
      [
        `<html>
        <head>
        </head>
          <body onload="window.print()" onafterprint="window.close()">
          <div style="padding-top: 1px;display:block;">
            <div style="content: '';width:100%; margin-top:20px;">
              ${qrlist?.map(
                (item, index) =>
                  `<div
                  key={${index}}
                  style="display: flex;flex-direction:column;align-items:center;justify-content:center;width: 100%;height:100%"
                >
                  <img src="${item?.image}" width="400px" height="400px" />
                  <div style="width:320px">
                    <div style="display:flex">
                      Hotel Name:${item?.hotel}
                    </div>
                    <div style="display:flex">
                      Location Name:${item?.location}
                    </div>
                  </div>
                </div>`
              )}
            </div>
          </div>
        </body>
        </html>`
      ].join("")
    )
    win.document.close()
  }

  return (
    <Layout>
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container>
          <div class="search">
            <span class="form-element">
              <span class="fa fa-search"></span>
              <input
                placeholder="SEARCH RECIPE"
                // value={searchText}
                onChange={value => filtered(value.target.value)}
              />
            </span>
          </div>
        </Grid>
        <Grid
          container
          justifyContent={"space-between"}
          className="mt-3"
          alignItems={"center"}
        >
          <Typography className="text_primary font-weight-bold">
            Recipe of the day
          </Typography>
          {/* <Grid
            container
            item
            md={6}
            xs={12}
            justifyContent={"space-between"}
            className={"mt-3"}
            spacing={2}
          >
            <Grid item md={4} xs={12}>
              <select
                className="zipcode"
                value={locationQR}
                onChange={value => {
                  filtered(value.target.value)
                  handleChange("locationQR", value.target.value)
                }}
              >
                <option value={""}>Select Location</option>
                {locationDropdown?.map((location, index) => (
                  <option key={index} value={location?.title}>
                    {location?.title}
                  </option>
                ))}
              </select>
            </Grid>
            <Grid item md={4} xs={12}>
              <select
                className="zipcode"
                value={hotel}
                onChange={value => {
                  filtered(value.target.value)
                  handleChange("hotel", value.target.value)
                }}
              >
                <option value={""}>Select Hotel</option>
                {hotelsDropdown?.map((hotel, index) => (
                  <option key={index} value={hotel?.name}>
                    {hotel?.name}
                  </option>
                ))}
              </select>
            </Grid>
            <Grid item md={3} xs={12}>
              <IconButton
                className="primaryButton mt-1"
                disabled={qrlist.length === 0}
                style={{
                  marginRight: 10,
                  opacity: qrlist.length === 0 ? 0.6 : 1
                }}
                onClick={printAllImage}
              >
                <Typography>Print All</Typography>
              </IconButton>
            </Grid>
          </Grid> */}
        </Grid>
        {QRCodes && (
          <AppTable
            onClickButton={printImage}
            qrcode
            loading={loading}
            rows={filteredList}
            headingLeft={"Feedback recieved"}
            headingRight={""}
            count={count}
            clickNext={clickNext}
            offsetParent={page}
            // clickPre={clickPre}
            headCells={headCells1}
          />
        )}
      </Container>
    </Layout>
  )
}

export default function GenerateQR() {
  return <GenerateQRContent />
}
