import "./App.css"
import { Route, Routes, useNavigate } from "react-router-dom"
// import { PrivateRoute, Loader } from './components'
import { createTheme, ThemeProvider } from "@mui/material/styles"

//ROUTES
import * as ROUTES from "./constants/routes"

//CONTAINERS
import {
  Login,
  Location,
  Users,
  Documents,
  GenerateQR,
  FlaggedPost,
  Activity,
  Feedbacks,
  Issues
} from "./containers"
import AppContext from "./Context"
import {
  getAllUsers,
  getPayments,
  getOldQRCodes,
  getQRCodes,
  getFlaggedPost,
  getDocuments,
  getCategory,
  getLocation,
  getChecklist,
  getTools,
  getAllFeedbacks,
  getHotelsDropdown,
  getLocationDropdown
} from "./api/admin"
import { useState } from "react"

const theme = createTheme({
  palette: {
    primary: {
      main: "#D70404",
    },
  },
})
function App() {
  const navigate = useNavigate()
  const [user, setUser] = useState(null)
  const [dashboard, setDashboard] = useState(null)
  const [allUsers, setAllUsers] = useState([])
  const [documents, setDocuments] = useState([])
  const [requests, setRequests] = useState([])
  const [QRCodes, setQRCodes] = useState([])
  const [OldQRCodes, setOldQRCodes] = useState([])
  const [categories, setCategories] = useState([])
  const [locations, setLocations] = useState([])
  const [checklists, setChecklist] = useState([])
  const [tools, setTools] = useState([])
  const [hotelsDropdown, setHotelsDropdown] = useState([])
  const [locationDropdown, setLocationDropdown] = useState([])
  const [feedbacks, setFeedbacks] = useState([])

  const getError = error => {
    const errorText = Object.values(error?.response?.data)
    if (
      error?.response?.data?.detail === "Invalid token" ||
      errorText?.includes("Invalid token")
    ) {
      localStorage.removeItem("token")
      localStorage.removeItem("user")
      navigate("/")
    } else {
      alert(`Error: ${errorText[0]}`)
    }
  }

  const _getPayments = async () => {
    try {
      const token = localStorage.getItem("token")
      if (!token) {
        navigate("/")
        return
      }
      const res = await getPayments(token)
      setDashboard(res?.data)
    } catch (error) {
      getError(error)
    }
  }

  const _getAllUsers = async () => {
    try {
      const token = localStorage.getItem("token")
      const res = await getAllUsers("", token)
      setAllUsers(res?.data)
    } catch (error) {
      getError(error)
    }
  }

  const _getDocuments = async () => {
    try {
      const token = localStorage.getItem("token")
      const res = await getDocuments(token)
      setDocuments(res?.data?.results)
    } catch (error) {
      // alert(`Error: ${errorText[0]}`)
    }
  }

  const _getFlaggedPost = async () => {
    try {
      const token = localStorage.getItem("token")
      const res = await getFlaggedPost("", token)
      setRequests(res?.data?.results)
    } catch (error) {
      getError(error)
    }
  }

  const _getQRCodes = async () => {
    try {
      const token = localStorage.getItem("token")
      const res = await getQRCodes("", token)
      setQRCodes(res?.data?.results)
    } catch (error) {
      getError(error)
    }
  }

  const _getOldQRCodes = async () => {
    try {
      const token = localStorage.getItem("token")
      const res = await getOldQRCodes(token)
      setOldQRCodes(res?.data?.results)
    } catch (error) {
      getError(error)
    }
  }

  const _getCategory = async () => {
    try {
      const token = localStorage.getItem("token")
      const res = await getCategory(token)
      setCategories(res?.data?.results)
    } catch (error) {
      getError(error)
    }
  }

  const _getLocation = async () => {
    try {
      const token = localStorage.getItem("token")
      const res = await getLocation(token)
      setLocations(res?.data?.results)
    } catch (error) {
      getError(error)
    }
  }

  const _getChecklist = async () => {
    try {
      const token = localStorage.getItem("token")
      const res = await getChecklist(token)
      setChecklist(res?.data?.results)
    } catch (error) {
      getError(error)
    }
  }

  const _getTools = async () => {
    try {
      const token = localStorage.getItem("token")
      const res = await getTools(token)
      setTools(res?.data?.results)
    } catch (error) {
      getError(error)
    }
  }

  const _getHotelsDropdown = async () => {
    try {
      const token = localStorage.getItem("token")
      const res = await getHotelsDropdown(token)
      setHotelsDropdown(res?.data)
    } catch (error) {
      getError(error)
    }
  }

  const _getLocationDropdown = async () => {
    try {
      const token = localStorage.getItem("token")
      const res = await getLocationDropdown(token)
      setLocationDropdown(res?.data)
    } catch (error) {
      getError(error)
    }
  }

  const _getAllFeedbacks = async () => {
    try {
      const token = localStorage.getItem("token")
      const res = await getAllFeedbacks(token)
      setFeedbacks(res?.data)
    } catch (error) {
      getError(error)
    }
  }

  return (
    <AppContext.Provider
      value={{
        setUser,
        user,
        dashboard,
        _getPayments,
        _getAllUsers,
        allUsers,
        _getDocuments,
        Documents,
        QRCodes,
        _getQRCodes,
        _getFlaggedPost,
        requests,
        OldQRCodes,
        _getOldQRCodes,
        documents,
        _getCategory,
        _getLocation,
        locations,
        categories,
        _getChecklist,
        checklists,
        _getTools,
        tools,
        feedbacks,
        _getAllFeedbacks,
        hotelsDropdown,
        _getHotelsDropdown,
        locationDropdown,
        _getLocationDropdown
      }}
    >
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path={ROUTES.MAIN} element={<Login />} />
          {/* <Route path={ROUTES.DASHBOARD} element={<Dashboard />} /> */}
          <Route path={ROUTES.LOCATION} element={<Location />} />
          <Route path={ROUTES.FEEDBACKS} element={<Feedbacks />} />
          <Route path={ROUTES.USER} element={<Users />} />
          <Route path={ROUTES.DOCUMENTS} element={<Documents />} />
          <Route path={ROUTES.GENERATEQR} element={<GenerateQR />} />
          <Route path={ROUTES.FLAGGED_POST} element={<FlaggedPost />} />
          <Route path={ROUTES.ISSUES} element={<Issues />} />
          <Route path={ROUTES.ACTIVITY} element={<Activity />} />
        </Routes>
      </ThemeProvider>
    </AppContext.Provider>
  )
}

export default App
