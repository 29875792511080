export const getError = (error,navigate) => {
  const errorText = Object.values(error?.response?.data)
  if (
    error?.response?.data?.detail === "Invalid token" ||
    errorText?.includes("Invalid token")
  ) {
    localStorage.removeItem("token")
    localStorage.removeItem("user")
    navigate("/")
  } else {
    alert(`Error: ${errorText[0]}`)
  }
}
