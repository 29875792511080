import * as React from 'react'
import { Grid, Container, Paper, Dialog, Typography } from '@mui/material'
import { Layout } from '../../components'
import AppTable from '../../components/AppTable'
import { useContext } from 'react'
import AppContext from '../../Context'
import { useState } from 'react'
import { deleteUser, updateUser } from '../../api/admin'
import activeUser from '../../assets/svg/activeUser.svg'
import locationIcon from '../../assets/svg/location.svg'
import checklistIcon from '../../assets/svg/checklist.svg'
import visitIcon from '../../assets/svg/visit.svg'
import { useEffect } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  ArcElement,
  Legend
} from 'chart.js'
import { Line, Bar, Doughnut } from 'react-chartjs-2'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

ChartJS.register(
  CategoryScale,
  LinearScale,
  ArcElement,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
)

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false
    },
    title: {
      display: false
    }
  }
}

export const options1 = {
  cutout: 80,
  responsive: true,
  plugins: {
    legend: {
      display: false
    },
    title: {
      display: false
    }
  }
}

const headCells1 = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name'
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Email'
  },
  {
    id: 'role',
    numeric: false,
    disablePadding: false,
    label: 'Role'
  },
  {
    id: 'qr_scan.time',
    numeric: false,
    disablePadding: false,
    label: 'QR Code Scanned Time'
  },
  {
    id: 'qr_scan.location',
    numeric: false,
    disablePadding: false,
    label: 'QR Code Scanned Location'
  },

  {
    id: 'latest_issue',
    numeric: false,
    disablePadding: false,
    label: 'Issues'
  },
  {
    id: 'latest_issue.created_at',
    numeric: false,
    disablePadding: false,
    label: 'Issue Date & Time'
  },
  {
    id: 'latest_issue.checklist.location',
    numeric: false,
    disablePadding: false,
    label: 'Issue Location'
  },
  {
    id: 'latest_issue.image',
    numeric: false,
    disablePadding: false,
    label: 'Issue Image'
  },

  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    label: 'Action'
  }
]
const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Full Name'
  },
  {
    id: 'role',
    numeric: false,
    disablePadding: false,
    label: 'Reason'
  },
  {
    id: 'action',
    numeric: false,
    request: true,
    buttonText: 'Decline',
    buttonText1: 'Accept',
    disablePadding: false,
    label: 'Action'
  }
]

function DashboardContent () {
  const navigate = useNavigate()
  const { users, dashboard, requests, _getDocuments } = useContext(AppContext)

  const data = {
    labels: dashboard?.user_analytics?.monthly_count?.map(e => e.month),
    datasets: [
      {
        label: '',
        data: dashboard?.user_analytics?.monthly_count?.map(
          e => e.sum_by_month
        ),
        borderColor: 'rgba(2, 94, 155, 1)',
        backgroundColor: 'rgba(2, 94, 155, 1)'
      }
    ]
  }

  const data1 = {
    labels: dashboard?.user_analytics?.weekly_count?.map(e => e.day),
    datasets: [
      {
        label: '',
        data: dashboard?.user_analytics?.weekly_count?.map(e => e.sum_by_day),
        borderColor: 'rgba(2, 94, 155, 1)',
        backgroundColor: 'rgba(2, 94, 155, 1)'
      }
    ]
  }

  const dataQR = {
    labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
      {
        label: '# of Votes',
        data: [
          dashboard?.qr_counts?.active_qr,
          dashboard?.qr_counts?.inactive_qr,
          dashboard?.qr_counts?.old_qr
        ],
        backgroundColor: [
          'rgba(49, 212, 104, 1)',
          'rgba(191, 191, 191, 1)',
          'rgba(255, 255, 255, 1)'
        ],
        borderWidth: 0
      }
    ]
  }

  const [state, setState] = useState({
    open: ''
  })
  const { open } = state
  const handleChange = (name, value) => {
    setState(pre => ({ ...pre, [name]: value }))
  }

  const _updateUser = async (id, flag) => {
    try {
      const token = localStorage.getItem('token')
      const payload = {
        flag
      }
      await updateUser(id, payload, token)
      _getDocuments()
    } catch (error) {
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  function onResumeClick (link) {
    window.open(link)
  }

  return (
    <Layout>
      <Container maxWidth='lg' sx={{ mt: 4, mb: 4 }}>
        <Grid container direction={'column'}>
          <div className='mainHeading'>Dashboard</div>
          <div className='textGrey mb-4'>{moment().format('LL')}</div>
        </Grid>
        <Grid
          container
          alignItems={'flex-start'}
          justifyContent={'flex-start'}
          sx={{ alignItems: 'flex-start' }}
          spacing={2}
        >
          <Grid xs={12} md={8} item>
            <Paper elevation={5}>
              <Grid container spacing={2} sx={{ p: 2 }}>
                <Grid xs={12} alignItems={'center'} sx={{ p: 1 }} sm={6} md={3}>
                  <Typography className='headingactive'>
                    Active Users
                  </Typography>
                  <div className='blueBox'>
                    <div className='whiteBoxRound'>
                      <img src={activeUser} />
                    </div>
                    <div className='bigText'>{dashboard?.active_users}</div>
                    <div className='whiteText'>{'Users'}</div>
                  </div>
                </Grid>
                <Grid xs={12} alignItems={'center'} sx={{ p: 1 }} sm={6} md={3}>
                  <Typography className='headingactive'>
                    Total Visits
                  </Typography>
                  <div className='blueBox'>
                    <div className='whiteBoxRound'>
                      <img src={visitIcon} />
                    </div>
                    <div className='bigText'>
                      {dashboard?.user_analytics?.total_visits}
                    </div>
                    <div className='whiteText'>{'Visiters'}</div>
                  </div>
                </Grid>
                <Grid xs={12} alignItems={'center'} sx={{ p: 1 }} sm={6} md={3}>
                  <Typography className='headingactive'>Locations</Typography>
                  <div className='blueBox'>
                    <div className='whiteBoxRound'>
                      <img src={locationIcon} />
                    </div>
                    <div className='bigText'>{dashboard?.locations}</div>
                    <div className='whiteText'>{'Location'}</div>
                  </div>
                </Grid>
                <Grid xs={12} alignItems={'center'} sx={{ p: 1 }} sm={6} md={3}>
                  <Typography className='headingactive'>Checklists</Typography>
                  <div className='blueBox'>
                    <div className='whiteBoxRound'>
                      <img src={checklistIcon} />
                    </div>
                    <div className='bigText'>{dashboard?.checklists}</div>
                    <div className='whiteText'>{'Lists'}</div>
                  </div>
                </Grid>
              </Grid>
            </Paper>
            <Paper elevation={5}>
              <Grid sx={{ p: 2 }}>
                <div className='headingactive text-left'>
                  Monthly User Count
                </div>
                <Line options={options} data={data} />
              </Grid>
            </Paper>
            {dashboard?.user_list && (
              <AppTable
                rows={dashboard?.user_list}
                flagAction={_updateUser}
                onClickButton={() => navigate('/users')}
                headingTable={'List Of Users'}
                document
                rowsPage={15}
                noPagination
                headCells={headCells1}
              />
            )}
          </Grid>
          <Grid xs={12} md={4} item>
            {dashboard?.login_requests?.length > 0 && (
              <AppTable
                rows={dashboard?.login_requests}
                height={200}
                deleteAction={() => navigate('/requests')}
                onClickItem={() => navigate('/requests')}
                headingTable={'Login Request'}
                noPagination
                headingRight={''}
                nowarning
                headCells={headCells}
              />
            )}
            <Paper elevation={5}>
              <Grid sx={{ p: 2, mt: 2 }}>
                <div className='blueBox1'>
                  <div className='qrcodeHead'>{'QR Code'}</div>
                  <div className='doughnut'>
                    <Doughnut options={options1} data={dataQR} />
                  </div>
                  <div className='doughnutCircle'>
                    <div className='whiteText'>
                      {dashboard?.qr_counts?.total_qr}
                    </div>
                    <div className='whiteGrey'>Total QR Code</div>
                    <div className='whiteGrey'>Scan</div>
                  </div>
                  <div className='rowLegend'>
                    <div
                      className={'dotColor'}
                      style={{ backgroundColor: 'rgba(49, 212, 104, 1)' }}
                    />
                    <div className='textWhite ml-2 mr-2'>Current</div>
                    <div
                      className={'dotColor'}
                      style={{ backgroundColor: 'rgba(191, 191, 191, 1)' }}
                    />
                    <div className='textWhite ml-2 mr-2'>Inactive </div>
                    <div
                      className={'dotColor'}
                      style={{ backgroundColor: 'rgba(255, 133, 64, 1)' }}
                    />
                    <div className='textWhite ml-2'>Old</div>
                  </div>
                </div>
              </Grid>
            </Paper>
            <Paper elevation={5}>
              <Grid sx={{ p: 2, mt: 2 }}>
                <div className='headingactive text-left'>
                  Weekly User Counts
                </div>
                <Bar options={options} data={data1} />
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}

export default function Dashboard () {
  return <DashboardContent />
}
