import { Button, Grid, Paper, TablePagination } from "@mui/material"
import React, { Component } from "react"
import trashIcon from "../../assets/svg/trash.svg"
import minus from "../../assets/svg/minus.svg"
import plus from "../../assets/svg/plus.svg"

function Tools({
  handleChange,
  locations,
  _deleteLocation,
  addnew,
  tools,
  _deleteTools,
  hours,
  minutes,
  titleTool,
  _addTools,
  accessibility1,
  offsetParent,
  rowsPage,
  clickNext,
  count
}) {
  const [page, setPage] = React.useState(offsetParent || 0)
  const [rowsPerPage, setRowsPerPage] = React.useState(rowsPage || 20)

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    if (clickNext) {
      const offset =
        newPage === page + 1 ? offsetParent + 20 : offsetParent - 20
      clickNext && clickNext(offset)
    }
  }
  return (
    <Paper>
      <Grid container justifyContent={"space-between"}>
        <Grid item md={6} xs={12} sx={{ p: 1 }}>
          <div className="head">Site Configuration Tool</div>
          {tools?.map((item, index) => (
            <Grid
              container
              justifyContent={"space-between"}
              className={"listItem"}
            >
              <div>{item?.title}</div>
              <div
                className="c-pointer"
                onClick={() => _deleteTools(item?.id, item?.title, "tool")}
              >
                <img src={trashIcon} />
              </div>
            </Grid>
          ))}
        </Grid>
        <Grid item md={addnew ? 3 : 6} xs={12} sx={{ p: 1 }}>
          <div className="head">Locations</div>
          {locations?.map((item, index) => (
            <Grid
              key={index}
              container
              justifyContent={"space-between"}
              className={"listItem"}
            >
              <div>{item?.title}</div>
              <div
                className="c-pointer"
                onClick={() =>
                  _deleteLocation(item?.id, item?.title, "location")
                }
              >
                <img src={trashIcon} />
              </div>
            </Grid>
          ))}
          <TablePagination
            rowsPerPageOptions={[20]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <div className="blueBox1">
            <div className="text-white">Time to Start Visit</div>
            <Grid container justifyContent={"space-around"}>
              <div className="timerDiv">
                <div className="hourHead text-white text-center">Hour</div>
                <div className="greyBoxTimer">
                  <div className="timeBox">{"10"}</div>
                  <div
                    className="roundWhiteBox"
                    // onClick={() =>
                    //   handleChange('hours', hours > 0 ? hours - 1 : 0)
                    // }
                  >
                    <img src={minus} />
                  </div>
                  <div
                    className="roundWhiteBox"
                    // onClick={() =>
                    //   handleChange('hours', hours < 23 ? hours + 1 : 23)
                    // }
                  >
                    <img src={plus} className={"borderBlue"} />
                  </div>
                </div>
              </div>
              <div className="timerDiv">
                <div className="hourHead text-white text-center">Minute</div>
                <div className="greyBoxTimer">
                  <div className="timeBox">{"10"}</div>
                  <div
                    className="roundWhiteBox"
                    // onClick={() =>
                    //   handleChange('hours', hours > 0 ? hours - 1 : 0)
                    // }
                  >
                    <img src={minus} />
                  </div>
                  <div
                    className="roundWhiteBox"
                    // onClick={() =>
                    //   handleChange('hours', hours < 23 ? hours + 1 : 23)
                    // }
                  >
                    <img src={plus} className={"borderBlue"} />
                  </div>
                </div>
              </div>
            </Grid>
            <Button className={"whitePrimaryButton"}>Save</Button>
          </div>
        </Grid>
        {addnew && (
          <Grid item md={3} xs={12}>
            <div className={"zipModal"}>
              <Grid
                container
                direction={"column"}
                justifyContent={"center"}
                className={"mt-3"}
              >
                <div className="text_primary font-weight-bold text-center mb-4">
                  Add New Tool
                </div>

                <Grid item xs={12} className={"mt-3"}>
                  <input
                    className={"zipcode"}
                    placeholder="Title of the Tool"
                    onChange={value =>
                      handleChange("titleTool", value.target.value)
                    }
                  />
                </Grid>

                <select
                  className="zipcode mt-3 mb-5"
                  onChange={value =>
                    handleChange("accessibility1", value.target.value)
                  }
                >
                  <option value={""}>Can be Accessed By</option>
                  <option value={"Area Manager"}>Area Manager</option>
                  <option value={"General Manager"}>General Manager</option>
                  <option value={"Staff"}>Staff</option>
                  <option value={"Contract Maintenance"}>
                    Contract Maintenance
                  </option>
                  <option value={"House Keeping"}>House Keeping</option>
                  <option value={"Admin"}>Admin</option>
                </select>
                <div
                  // onClick={() => handleChange('openCat', !openCat)}
                  className="categoryDropdown d-flex justify-content-between"
                >
                  <div className="text_primary">{"Set Time"}</div>
                </div>
                <div className="timerDiv">
                  <div className="hourHead">Hour</div>
                  <div className="greyBoxTimer">
                    <div className="timeBox">{hours}</div>
                    <div
                      className="roundWhiteBox"
                      onClick={() =>
                        handleChange("hours", hours > 0 ? hours - 1 : 0)
                      }
                    >
                      <img src={minus} />
                    </div>
                    <div
                      className="roundWhiteBox"
                      onClick={() =>
                        handleChange("hours", hours < 23 ? hours + 1 : 23)
                      }
                    >
                      <img src={plus} className={"borderBlue"} />
                    </div>
                  </div>
                </div>
                <div className="timerDiv">
                  <div className="hourHead">Minute</div>
                  <div className="greyBoxTimer">
                    <div className="timeBox">{minutes}</div>
                    <div
                      className="roundWhiteBox"
                      onClick={() =>
                        handleChange("minutes", minutes > 0 ? minutes - 1 : 0)
                      }
                    >
                      <img src={minus} />
                    </div>
                    <div
                      className="roundWhiteBox"
                      onClick={() =>
                        handleChange("minutes", minutes < 59 ? minutes + 1 : 59)
                      }
                    >
                      <img src={plus} className={"borderBlue"} />
                    </div>
                  </div>
                </div>
                <Grid container justifyContent={"center"}>
                  <Button
                    disabled={
                      !accessibility1 || !titleTool || !hours || !minutes
                    }
                    style={{
                      opacity:
                        !accessibility1 || !titleTool || !hours || !minutes
                          ? 0.4
                          : 1
                    }}
                    onClick={_addTools}
                    className="primaryButton"
                  >
                    Save
                  </Button>
                  <Button
                    onClick={() => handleChange("addnewTool", false)}
                    className="primaryButton ml-3"
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Grid>
        )}
      </Grid>
    </Paper>
  )
}

export default Tools
